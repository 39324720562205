

<body>
  <div class="card mb-3">
    <div class="row g-0">
      <div class="col-md-4 card text-bg-light">
        <img src={{valor2}} class="img-fluid rounded-start" alt="...">
      </div>
      <div class="col-md-8">
        <div class="card-body">
          <h1 class="card-title">{{valor}}</h1>
          <p class="card-text" style="text-align: justify;">{{valor1}}</p>
          <p class="card-text" style="text-align: justify;">{{valor4}}</p>
          <p class="card-text" style="text-align: justify;">{{valor5}}</p>
          <p class="card-text" style="text-align: justify;">{{valor6}}</p>
          <p class="card-text" style="text-align: justify;">{{valor7}}</p>
          <p class="card-text" style="text-align: justify;">{{valor8}}</p>
        </div>
      </div>
    </div>
  </div>
</body>

<app-footer></app-footer>