import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './footer/footer.component';
import { InicioComponent } from './inicio/inicio.component';
import { NavbarComponent } from './navbar/navbar.component';

import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

/* import { FormControl, FormGroup } from '@angular/forms'; */






/* import { AgendaComponent } from './agenda/agenda.component'; */
import { RouterModule } from '@angular/router';
/* import { ZonaComponent } from './zona/zona.component'; */
import { MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
/* import { AlaireComponent } from './alaire/alaire.component'; 
import { ActividadesComponent } from './actividades/actividades.component'; 
import { RegistroComponent } from './registro/registro.component';
import { TablonanunciosComponent } from './tablonanuncios/tablonanuncios.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ToastModule } from '@syncfusion/ej2-angular-notifications'; */
//Importaciones de los recursos del translate
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {MatExpansionModule} from '@angular/material/expansion';


/* import { MatSelectionList } from '@angular/material/list'; */

/* import { MatNavList } from '@angular/material/list'; */
/* import { _MatSelectBase } from '@angular/material/select'; */

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PublicacionesComponent } from './publicaciones/publicaciones.component';
import { LibrosComponent } from './publicaciones/libros/libros.component';
import { ArticulosComponent } from './publicaciones/articulos/articulos.component';
import { SelecciontextosComponent } from './publicaciones/selecciontextos/selecciontextos.component';
import { AlandalusComponent } from './alandalus/alandalus.component';
import { FuentesComponent } from './alandalus/fuentes/fuentes.component';
import { ObrasComponent } from './alandalus/obras/obras.component';
import { EstudiosComponent } from './alandalus/estudios/estudios.component';
import { MasinfoComponent } from './masinfo/masinfo.component';
import { EsquinaComponent } from './masinfo/esquina/esquina.component';
import { EnlacesComponent } from './masinfo/enlaces/enlaces.component';
import { MultimediaComponent } from './masinfo/multimedia/multimedia.component';
import { VideosComponent } from './masinfo/multimedia/videos/videos.component';
import { FotosComponent } from './masinfo/multimedia/fotos/fotos.component';
import { PlantillaComponent } from './plantilla/plantilla.component';
import { PlazaComponent } from './masinfo/plaza/plaza.component';
import { AudiosComponent } from './masinfo/multimedia/audios/audios.component';
import { SobreComponent } from './sobre/sobre.component';
import { PresentacionComponent } from './sobre/presentacion/presentacion.component';
import { SaludoComponent } from './sobre/saludo/saludo.component';
import { NotaComponent } from './sobre/nota/nota.component';
import { ActividadesComponent } from './sobre/actividades/actividades.component';
import { CriticasComponent } from './sobre/criticas/criticas.component';
import { ContactoComponent } from './contacto/contacto.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ErrorComponent } from './error/error.component';











@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    InicioComponent,
    LibrosComponent,
    PublicacionesComponent,
    ArticulosComponent,
    SelecciontextosComponent,
    AlandalusComponent,
    FuentesComponent,
    ObrasComponent,
    EstudiosComponent,
    MasinfoComponent,
    EsquinaComponent,
    EnlacesComponent,
    MultimediaComponent,
    VideosComponent,
    FotosComponent,
    PlantillaComponent,
    PlazaComponent,
    AudiosComponent,
    SobreComponent,
    PresentacionComponent,
    SaludoComponent,
    NotaComponent,
    ActividadesComponent,
    CriticasComponent,
    ContactoComponent,
    ErrorComponent

    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatMenuModule,
    MatGridListModule,
    MatCardModule,
    MatBadgeModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatToolbarModule,
    MatDividerModule,
    MatTableModule,
    MatTooltipModule,
/*     MatExpansionModule,
    ToastModule, */
    HttpClientModule,
    MatListModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    /* MatChipList, */
    MatSelectModule,
    MatExpansionModule,
    /* MatSelectionList, */
    
    /* MatNavList, */
    /* FormControl, 
    FormGroup, */
    TranslateModule.forRoot({
    loader:{
      provide:TranslateLoader,
      useFactory:(http:HttpClient)=>{
        return new TranslateHttpLoader(http);
       },
      deps:[HttpClient]
      }
    }),
    RouterModule.forRoot([
      {path: 'inicio', component: InicioComponent},
      {path: 'publicaciones', component: PublicacionesComponent},
      {path: 'libros', component: LibrosComponent},
      {path: 'articulos', component: ArticulosComponent},
      {path: 'selecciontextos', component: SelecciontextosComponent},
      {path: 'alandalus', component: AlandalusComponent},
      {path: 'fuentes', component: FuentesComponent},
      {path: 'obras', component: ObrasComponent},
      {path: 'estudios', component: EstudiosComponent},
      {path: 'masinfo', component: MasinfoComponent},
      {path: 'esquina', component: EsquinaComponent},
      {path: 'enlaces', component: EnlacesComponent},
      {path: 'sobre', component: SobreComponent},
      {path: 'plantilla/:id', component: PlantillaComponent},
      {path: 'contacto', component: ContactoComponent},
      {path: '', redirectTo: '/inicio', pathMatch: 'full'},
      {path:'**',component:ErrorComponent}
    ]),
    HttpClientModule
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }









