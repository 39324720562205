import { Component, OnInit } from '@angular/core';
/* import { Console } from 'console'; */


@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})





export class InicioComponent implements OnInit {

  
  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  

  valor:string="";
  numero:number = this.getRandomInt(96);

  frases:any =[
    {frase:"“El hombre es la medida de todas las cosas, de las que son en cuanto que son, y de las que no son en cuanto que no son” Protágoras de Abdera, Fragmentos."},
    {frase:"“El humor es uno de los elementos del genio”: Johann W. Goethe, Máximas y reflexiones."},
    {frase:"“ Dijo el sabio: la sabiduría del pobre es a menudo despreciada, y la necedad del rico es encubierta”: Ibn Gabirol, Selección de perlas."},
    {frase:"“ Sea moderado tu sueño, que el que no madruga con el sol no goza del día”: Miguel de Cervantes, Don Quijote."},
    {frase:"“¿Qué, pensáis vosotros que los reyes hacen la guerra con el bronce de las bombardas, con el hierro de los mosquetes y con el plomo de las balas? Que no, por cierto, sino con dinari, y dinari e piu dinari”: Baltasar Gracián, El Criticón."},
    {frase:"“A los andaluces –decía mi maestro- nos falta fantasía para artistas; nos sobra, en cambio, sentido metafísico para filósofos occidentales. Con todo, es el camino de la filosofía el que nosotros debemos preferentemente seguir”: Antonio Machado, Juan de Mairena."},
    {frase:"“A quien tiene grande el pecho no le ahogan cosas grandes”: Francisco de Quevedo, Sentencias."},
    {frase:"“A vosotros no os importe pensar lo que habéis leído ochenta veces y oído quinientas, porque no es lo mismo pensar que haber leído”: Antonio Machado, Juan de Mairena."},
    {frase:"“Además, no se debe considerar feliz al Estado, ni elogiar al legislador por ejercitar a los ciudadanos para dominar a sus vecinos; esto, en efecto, encierra un gran daño”: Aristóteles, Política."},
    {frase:"“Al alabar el régimen de Lacedemonia muchos autores admiran el objetivo del legislador que lo ordenó todo a la dominación y la guerra, lo cual no sólo puede ser refutado fácilmente por la razón sino que lo ha sido por los hechos” Aristóteles, Política ."},
    {frase:"“Algunos convierten en crematísticas todas las facultades, como si el producir dinero fuese el fin de todas ellas y todo tuviera que encaminarse a ese fin” Aristóteles, Política."},
    {frase:"“Así como la gravedad es la sustancia de la materia, así es la libertad la sustancia del espíritu”: G.W.F. Hegel, Lecciones sobre la filosofía de la historia universal."},
    {frase:"“Cada uno es hijo de sus obras” Miguel de Cervantes, Don Quijote"},
    {frase:"“César venció a los galos. ¿No llevaba consigo ni siquiera un cocinero? Felipe II lloró al hundirse su flota. ¿No lloró nadie más?” Bertold Brecht, Poemas y Canciones ."},
    {frase:"“Conócese la prudencia en lo serio, que está más acreditado que lo ingenioso. El que siempre está de burlas, nunca es hombre de veras”: Baltasar Gracián, Oráculo manual y arte de prudencia."},
    {frase:"“Conviene liberarse de la cárcel de los negocios y de la política”: Epicuro, Sentencias Vaticanas."},
    {frase:"“Cuando el hombre deja de creer en lo absoluto, ya no cree en nada. Porque toda creencia es creencia en lo absoluto. Todo lo demás se llama pensar” Antonio Machado, Juan de Mairena ."},
    {frase:"“Cuando la verdad es abstracta, no es tal verdad”: G.W.F. Hegel, Lecciones sobre la historia de la filosofía."},
    {frase:"“Cuando se quiebra el afecto de los hijos por los padres, la sociedad se arruina”: Averroes, Exposición de la«República» de Platón."},
    {frase:"“De todos aquellos bienes que procura la sabiduría para la completa felicidad de la vida, el más grande de todos es el logro de la amistad” Epicuro, Máximas Capitales ."},
    {frase:"“Diferenciar el hombre de palabras del de obras. Es única precisión como la del amigo. Ya no se come de palabras, que son viento, ni se vive de cortesías, que es un cortés engaño; las palabras han de ser prendas de las obras, y así han de tener valor”: Baltasar Gracián, Oráculo manual y arte de prudencia."},
    {frase:"“Donde hay poca justicia es gran peligro tener razón” Francisco de Quevedo, Sentencias ."},
    {frase:"“El amigo es todas las cosas. No hay desierto como vivir sin amigos. La amistad es un desahogo del alma”: Baltasar Gracián, Oráculo manual y arte de prudencia."},
    {frase:"“El amor es la última filosofía de la tierra y del cielo”: Francisco de Quevedo, Sentencias."},
    {frase:"“El cristianismo — y éste es su mérito más hermoso— suavizó un tanto aquel brutal gusto germánico por la lucha, pero no pudo extirparlo, y cuando un día se rompa el talismán moderador, la cruz, volverá a estallar el salvajismo de los viejos guerreros, la loca cólera de los hombres-fieras de que tanto cantan y dicen los poetas nórdicos”: Heinrich Heine, Contribución a la historia de la religión y de la filosofía en Alemania."},
    {frase:"“El hermano, para el día malo”: Francisco de Quevedo, Sentencias."},
    {frase:"“El hombre sabe de sí mismo; y esto le diferencia del animal. Es un ser pensante; pero pensar es saber de lo universal”: G.W.F. Hegel, Lecciones sobre la filosofía de la historia universal."},
    {frase:"“El hombre viene al mundo y no sabe por qué; se alegra y no sabe de qué; vive y no sabe hasta cuándo”: Ibn Gabirol, Kéter Malkut."},
    {frase:"“El idealismo filosófico es sólo una tontería desde el punto de vista del materialismo tosco, simple, metafísico”: Lenin, Cuadernos filosóficos."},
    {frase:"“El máximo fruto de la autosuficiencia es la libertad”: Epicuro, Sentencias Vaticanas."},
    {frase:"“El que no puede vivir en sociedad, o no necesita nada por su propia suficiencia, no es miembro de la ciudad-Estado, sino una bestia o un dios”: Aristóteles, Política."},
    {frase:"“En España, que miró siempre de través a la cultura, el positivismo tuvo sólo una influencia negativa, que no fue compensada por el entusiasta apego al estudio de las ciencias particulares”: Antonio Machado, Los Complementarios."},
    {frase:"“En la universalización de lo particular consiste la esencia de la educación del sujeto”: G.W.F. Hegel, Lecciones sobre la filosofía de la historia universal."},
    {frase:"“En los tiempos que corren no debe nadie callar ni transigir; debemos movernos, no para avasallar a los demás, sino para mantenernos en nuestro puesto; que contemos con mayoría o con minoría, es indiferente” Johann W. Goethe, Máximas y reflexiones ."},
    {frase:"“En todas las cosas ocurre igual: las que crecen de prisa, de prisa se consumen, en tanto que las que tardan en nacer tardan también en acabarse” Ibn Hazm de Córdoba, El collar de la paloma ."},
    {frase:"“Es el oído la puerta segunda de la verdad y principal de la mentira. La verdad ordinariamente se ve, extravagantemente se oye”: Baltasar Gracián, , Oráculo manual y arte de prudencia."},
    {frase:"“Es mejor avanzar y morir que detenerse y morir” Proverbio zulú."},
    {frase:"“Es muy meritorio en las ciencias el buscar y exhumar aquellas verdades incompletas que ya poseían los antiguos”: Johann W. Goethe, Máximas y reflexiones."},
    {frase:"“Es necesario crear hombres sobrios, pacientes, que no desesperen ante los peores horrores y no se exalten por cualquier tontería. Pesimismo de la inteligencia, optimismo de la voluntad”: Antonio Gramsci, Cuadernos de la cárcel."},
    {frase:"“Es probablemente absurdo hacer al hombre dichoso solitario, porque nadie querría poseer todas las cosas a condición de estar solo; el hombre es, en efecto, un animal social y naturalmente formado para la convivencia”: Aristóteles, Ética nicomáquea."},
    {frase:"“Escribir historias es un modo de quitarse de encima el pasado”: Johann W. Goethe, Máximas y reflexiones."},
    {frase:"“Hay que educar a los hijos y a las mujeres con vistas al régimen constitucional, si en realidad el que los hijos y las mujeres sean como es debido tiene alguna importancia para que la ciudad-Estado lo sea también. Y necesariamente ha de tenerla, pues las mujeres son la mitad de la población libre y de los niños proceden los ciudadanos”: Aristóteles, Política."},
    {frase:"“Hemos descubierto el origen de la guerra en aquello de lo cual nacen las mayores catástrofes públicas y privadas que recaen sobre los Estados”: Platón, República."},
    {frase:"“La amistad es lo más necesario para la vida. Sin amigos nadie querría vivir, aun cuando poseyera todos los demás bienes”: Aristóteles, Ética nicomáquea."},
    {frase:"“La crisis consiste precisamente en que muere lo viejo sin que pueda nacer lo nuevo, y en ese interregno ocurren los más diversos fenómenos morbosos”: Antonio Gramsci, Cuadernos de la cárcel."},
    {frase:"“La flor de la guerra civil es infecunda”: Ibn Hazm de Córdoba, Los caracteres y la conducta."},
    {frase:"“La forma matemática da a Spinoza un aspecto duro. Pero ocurre como con la cáscara de la almendra: tanto más agradable es la almendra misma”: Heinrich Heine, Contribución a la historia de la religión y de la filosofía en Alemania."},
    {frase:"“La necesidad es un mal pero no hay ninguna necesidad de vivir bajo la necesidad”: Epicuro, Sentencias Vaticanas."},
    {frase:"“La sangre se hereda y la virtud se aquista, y la virtud vale por sí sola lo que la sangre no vale”: Miguel de Cervantes, Don Quijote."},
    {frase:"“La variedad con perfección es entretenimiento de la vida. Gran arte la de saber lograr todo lo bueno”: Baltasar Gracián, Oráculo manual y arte de prudencia."},
    {frase:"“La verdad no es una moneda acuñada, que pueda entregarse y recibirse sin más, tal y como es”: G.W.F. Hegel, Fenomenología del espíritu, Prólogo."},
    {frase:"“Las comunidades de muchos de los reyes musulmanes de hoy son casi exclusivamente asociaciones de estrictos clanes y las normas que siguen vigentes en ellas son las que les permiten mantener las leyes primarias...Las masas son explotadas por los poderosos, y estos progresan apropiándose de los bienes de aquellos, lo que a veces conduce a la tiranía, como sucede en nuestro tiempo y en nuestra sociedad”: Averroes, Exposición de la «República» de Platón."},
    {frase:"“Las necedades del rico por sentencias pasan en el mundo”: Miguel de Cervantes, Don Quijote."},
    {frase:"“Las preguntas del sabio son la mitad de su sabiduría; la afabilidad con el prójimo, la mitad de la sensatez; y la buena administración, la mitad de la economía”: Ibn Gabirol, Selección de perlas."},
    {frase:"“Lo bueno, si breve, dos veces bueno; y aun lo malo, si poco, no tan malo. Más obran quintas esencias que fárragos”: Baltasar Gracián, Oráculo manual y arte de prudencia."},
    {frase:"“Los mayores delitos tienen por causa los excesos, no la necesidad; por ejemplo, los hombres no se hacen tiranos para no pasar frío”: Aristóteles, Política."},
    {frase:"“Los yerros del hombre son los que verdaderamente le hacen amable” Johann W. Goethe, Máximas y reflexiones ."},
    {frase:"“Más vale un gramo de cordura que arrobas de sutileza” Baltasar Gracián, Oráculo manual y arte de prudencia ."},
    {frase:"“Nace bárbaro el hombre; redímese de bestia cultivándose. Hace personas la cultura, y más cuanto mayor”: Baltasar Gracián, Oráculo manual y arte de prudencia."},
    {frase:"“Nada grande se ha realizado en el mundo sin pasión” G.W.F. Hegel, Lecciones sobre la filosofía de la historia universal."},
    {frase:"“Nada hay más espantoso que una ignorancia activa”: Johann W. Goethe, Máximas y reflexiones."},
    {frase:"“Nada hay más indicativo de la mala conducta de los ciudadanos y de la ruindad de sus ideas que el hecho de que tengan necesidad de jueces y médicos, señal cierta de que carecen de cualquier clase de virtud y sólo las cumplen por la fuerza”: Averroes, Exposición de la «República» de Platón."},
    {frase:"“Ninguna nación se hace capaz de juicio sino cuando puede juzgarse a sí misma. Pero a esta gran ventaja llégase muy tarde”: Johann W. Goethe, Máximas y reflexiones."},
    {frase:"“Ninguna sociedad se plantea tareas para cuya solución no existan ya las condiciones necesarias y suficientes, o no estén, al menos, en vías de aparición o desarrollo” Antonio Gramsci, Cuadernos de la cárcel ."},
    {frase:"“No es un hombre más que otro si no hace más que otro”: Miguel de Cervantes, Don Quijote."},
    {frase:"“No habrá sitio para la belleza ni para el genio en la comunidad de nuestros nuevos puritanos, y una y otro serán acosados y reprimidos aún más tristemente que bajo regímenes antiguos”: Heinrich Heine, Ludwig Börne ."},
    {frase:"“No he visto cosa más parecida a este mundo que las sombras chinescas de la linterna mágica; son unas figuras montadas sobre una rueda de madera, la cual da vueltas con rapidez; un grupo de figuras desaparece, cuando otro grupo asoma”: Ibn Hazm de Córdoba, Los caracteres y la conducta."},
    {frase:"“No siempre puede estar tirante el arco. Ni lo más perfecto en los hombres se escapa del dominio del tiempo, de la jurisdicción del olvido”: Francisco de Quevedo, Sentencias."},
    {frase:"“Nuestra naturaleza nos lleva más bien a los placeres, y por eso somos más propensos al desenfreno que a la austeridad”: Aristóteles, Ética nicomáquea."},
    {frase:"“Nunca galgo que corre liebres las corre mal”: Francisco de Quevedo, Sentencias."},
    {frase:"“Palabras sin verdad, paja sin grano” Francisco de Quevedo, Sentencias ."},
    {frase:"“Pero no existe peor mal en el gobierno social que aquella política que hace de una sola sociedad varias, al igual que no hay mayor bien en las comunidades que aquello que las reúne y unifica” Averroes, Exposición de la «República» de Platón ."},
    {frase:"“Personas que pequen por defecto respecto de los placeres y se complazcan en ellos menos de lo debido, apenas existen, porque tal insensibilidad no es humana”: Aristóteles, Ética nicomáquea."},
    {frase:"“Por el amor, los tacaños se hacen desprendidos; los huraños desfruncen el ceño; los cobardes se envalentonan; los ásperos se vuelven sensibles; los ignorantes se pulen; los desaliñados se atildan; los sucios se limpian; los viejos se las dan de jóvenes; los ascetas rompen sus votos, y los castos se tornan disolutos”: Ibn Hazm de Córdoba, El collar de la paloma."},
    {frase:"“Quien aún esté vivo no diga «jamás». Lo firme no es firme. Todo no seguirá igual. Cuando hayan hablado los que dominan, hablarán los dominados”: Bertolt Brecht, Poemas y Canciones."},
    {frase:"“Sabemos que la mujer, en tanto que es semejante al varón, debe participar necesariamente del fin último del hombre, aunque existan diferencias en más o en menos... Del mismo modo, cuando algunas mujeres han sido muy bien educadas y poseían disposiciones sobresalientes, no ha resultado imposible que lleguen a ser filósofos y gobernantes”: Averroes, Exposición de la «República» de Platón."},
    {frase:"“Se puede decir que la mayor parte de los hombres son filósofos, en cuanto que actúan prácticamente y su actuar práctico (las líneas directrices de su conducta) contiene implícitamente una concepción del mundo, una filosofía”: Antonio Gramsci, cuadernos de la cárcel."},
    {frase:"“Si deseas ser amado, ama”: Francisco de Quevedo, Sentencias."},
    {frase:"“Si lo que desaparece se redujera a la nada, ya habrían perecido todas las cosas”: Epicuro, Carta a Heródoto."},
    {frase:"“Si quieres no temer ni esperar, da por pasada la vida”: Francisco de Quevedo, Sentencias."},
    {frase:"“Si, por tanto, el intelecto es divino respecto del hombre, también la vida según él es divina respecto de la vida humana”: Aristóteles, Ética nicomáquea."},
    {frase:"“Sobre los dioses no puedo saber ni que existen ni que no existen ni, respecto a su forma, cómo son. Pues muchas cosas me impiden saberlo, tanto la oscuridad del tema como la brevedad de la vida del hombre”: Protágoras de Abdera, Fragmentos."},
    {frase:"“Sólo el intelecto viene de fuera y sólo él es divino; porque una actividad corporal nada tiene en común con su actividad”: Aristóteles, Sobre la generación de los animales."},
    {frase:"“Sólo escribe una crónica aquél para quien tiene importancia lo presente”: Johann W. Goethe, Máximas y reflexiones."},
    {frase:"“Son los irreflexivos los que nunca dudan. Su digestión es espléndida, su juicio infalible. No creen en los hechos, sólo creen en sí mismos. (...) Frente a los irreflexivos, que nunca dudan, están los reflexivos que nunca actúan. No dudan para llegar a la decisión, sino para eludir la decisión”: Bertolt Brecht, Poemas y Canciones."},
    {frase:"“También juzga mejor una multitud que un individuo cualquiera. Además, una gran cantidad es más difícil de corromper; y así, la muchedumbre es más incorruptible que unos pocos”: Aristóteles, Política."},
    {frase:"“Tengo dos propiedades que me han hecho gustar tragos muy amargos, que han turbado mi vida y destrozado mi firmeza. Una es la lealtad a toda prueba. La otra es el amor propio”: Ibn Hazm de Córdoba, El collar de la paloma."},
    {frase:"“Todo poeta debe crearse una metafísica que no necesita exponer, pero que ha de hallarse implícita en su obra”: Antonio Machado, Los Complementarios."},
    {frase:"“Tres cosas se juntaron contra mí, extenuando mi carne corporal y mi penado espíritu: grande culpa, mucho sufrimiento y soledad. ¿Y quién podría resistir a las tres?” Ibn Gabirol, Poesía secular."},
    {frase:"“Tres cualidades son esenciales al verdadero sabio: no despreciar a los inferiores que van en busca de la sabiduría; no envidiar a los ricos, y no traficar con su sabiduría” Ibn Gabirol, Selección de perlas ."},
    {frase:"“Tres pasiones, simples, pero abrumadoramente intensas, han gobernado mi vida: el ansia de amor, la búsqueda del conocimiento y una insoportable piedad por el sufrimiento de la Humanidad” Bertrand Russell, Autobiografía , Prólogo."},
    {frase:"“Vale un buen amigo más que cien parientes”: Francisco de Quevedo, Sentencias."},
    {frase:"“Y cuanto más posea la virtud completa y más feliz sea, tanto más penosa le será la muerte, pues para un hombre así la vida es más preciosa que para nadie y tal hombre tendrá conciencia de privarse de los mayores bienes, y esto es doloroso”: Aristóteles, Ética nicomáquea."},
    {frase:"“Ya aquí en la tierra querría yo establecer, por medio de libres instituciones políticas e industriales, aquella bienaventuranza que en opinión de los piadosos no puede producirse sino el día del Juicio Final, en el Cielo”: Heinrich Heine, Contribución a la historia de la religión y de la filosofía en Alemania."},
    {frase:"“Yo imagino que es bueno mandar, aunque sea a un hato de ganado”: Miguel de Cervantes, Don Quijote."},
    {frase:"Todas las guerras se hacen para ganar dinero” Platón, Fedón ."}
    ];
   

  dameFrase():string{
     
      this.valor = this.frases[this.numero].frase; 
        
    return;
    
  }
 
  

  constructor() { 
    this.dameFrase()
  }

  ngOnInit(): void {
  }

}



