<body>
   
    <div class="alert alert-secondary" role="alert">
      <h1>{{'app.inicio4' | translate}}</h1>
      <hr>
    <div><img class="border border-light-subtle rounded" style="float: right;" title="Andrés Martínez Lorca" src="./../../assets/img//1.webp" alt="Andrés Martínez Lorca" width="37%" height="37%"> 
      <p>{{'app.inicio5' | translate}}</p>
    </div><br>
     <p>{{'app.inicio6' | translate}}</p>
     <p>{{'app.inicio7' | translate}}</p>
     <p>{{'app.inicio8' | translate}}</p>
     
     
    </div>
    
  </body>  





