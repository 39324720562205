<!DOCTYPE html>
<html lang="es">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Publicaciones</title>
    <!-- <link rel="canonical" href="https://www.andresmlorca.com/publicaciones"> -->
</head>
    <body>
        <div class="card" >
            <img src="./../../assets/img/1Llibreria.webp"  class="img-fluid rounded-start" height="200px" alt="...">
        </div>
        <br>
        <div class="container ">
            <h1>{{'app.pub1' | translate}}</h1>
            <mat-tab-group mat-stretch-tabs backgroundColor="primary" style="background-color: #4054b4; color: white;">
                <mat-tab label="{{'app.pub2' | translate}}" aria-label="primary" backgroundColor="secondary">
                    <app-libros></app-libros>
                </mat-tab>
                <mat-tab label="{{'app.pub3' | translate}}" aria-label="primary">
                    <app-articulos></app-articulos>
                </mat-tab>
                <mat-tab label="{{'app.pub4' | translate}}" aria-label="primary">
                    <app-selecciontextos></app-selecciontextos>
                </mat-tab>
            </mat-tab-group>
        </div>
    </body>   
</html>
<app-footer></app-footer>