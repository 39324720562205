
<!-- <h1>{{'app.info4' | translate}}</h1> -->

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 ">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
   
  
    <!-- Name Column -->
   
    <ng-container matColumnDef="frase">
      
      <th mat-header-cell *matHeaderCellDef mat-sort-header> <hr> <h1>{{'app.info4' | translate}}</h1></th>
      <td mat-cell *matCellDef="let element"> {{element.frase}} </td>
      <!-- zº -->
    </ng-container>
  
    
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <br>
  
  