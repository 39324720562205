<!DOCTYPE html>
<html lang="es">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Web del Profesor Andrés Martínez Lorca</title>
  <!-- <link rel="canonical" href="https://www.andresmlorca.com/"> -->
</head>



<div class="container">

  <body>
    <section>
      <img src="./../../assets/img/Alcazaba_de_Málaga_desde_el_puerto_edited.webp" class="img-fluid" alt="...">
    </section>



    <hr>




    <section> <!-- NOVEDADES -->

      
      <h2><b> {{'app.inicio2' | translate}}</b></h2>

      <div class="card-group" style="font-family: Tiempos, 'Playfair Display', serif; font-weight: 400;">
        <div class="card text-dark text-center" style="border: 0ch;">
          <hr>
          <!-- <hr style="width: 50%; margin-left: 25%;margin-right: 25%;"> -->
          <!-- <br> -->
          <p  style="text-align: center; font-size: x-large ; text-decoration:none; color: black;">MUY SOPHIA-FILOSOFÍA MEDIEVAL | MUY HISTORIA ED. COLECCIONISTA Nº 36</p>
          <!-- <hr style="width: 50%; margin-left: 25%;margin-right: 25%;"> -->
          <!-- <h3>Entrevista en "Entre líneas" ADEH TV</h3> -->
          <p  style="text-decoration:none; color: black;">
            
              
            <a href="./../../assets/pdf/MUY SOPHIA [92-105].pdf" target="_blank">
              <img src="../../assets/img/Portada Muy.png" loading="lazy" class="card-img-top" alt="...">
            </a>
            
          </p>
          <!-- <h3>Entrevista en "Entre líneas" ADEH TV</h3> -->
          <!-- <p style="color:white; background-color: black; ">BIBLIOTECA PÚBLICA POETA JUAN BERBEL, ALBOX (ALMERÍA)</p> -->
        </div> 

      </div>

      <!-- <div class="abs-center">
        <div class="row row-cols-1 row-cols-md-1 g-4" >
          <div class="col">
            <p  style="text-align: center; font-size: x-large ; text-decoration:none; color: black;">MUY SOPHIA-FILOSOFÍA MEDIEVAL | MUY HISTORIA ED. COLECCIONISTA Nº 36</p>
            <div class="card.jover card text-bg-light">
              <a href="./../../assets/pdf/MUY SOPHIA [92-105].pdf" target="_blank">
                <img src="../../assets/img/Portada Muy.png" loading="lazy" class="card-img-top" alt="...">
              </a>
            </div>
          </div>
        </div>
      </div> -->
<br>
      
      <div class="card-group" style="font-family: Tiempos, 'Playfair Display', serif; font-weight: 400;">
        <div class="card text-dark text-center" style="border: 0ch;">
          <hr>
          <!-- <hr style="width: 50%; margin-left: 25%;margin-right: 25%;"> -->
          <!-- <br> -->
          <p  style="text-align: center; font-size: x-large ; text-decoration:none; color: black;">Entrevista en "Entre líneas" ADEH TV</p>
          <!-- <hr style="width: 50%; margin-left: 25%;margin-right: 25%;"> -->
          <!-- <h3>Entrevista en "Entre líneas" ADEH TV</h3> -->
          <div class="video-responsive">
          <p  style="text-decoration:none; color: black;">
            
            
                  <iframe src="https://www.youtube.com/embed/a3bKKDt9E58?si=aB2zigSapOAHMtHy" 
                  
                    title="YouTube video player" frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen>
                  </iframe>
              
            
          </p>
        </div>
          <!-- <h3>Entrevista en "Entre líneas" ADEH TV</h3> -->
          <!-- <p style="color:white; background-color: black; ">BIBLIOTECA PÚBLICA POETA JUAN BERBEL, ALBOX (ALMERÍA)</p> -->
        </div> 

      </div>


  <hr>


      <div class="card-group" style="font-family: Tiempos, 'Playfair Display', serif; font-weight: 400;">
        <div class="card text-dark mb-3 border-primary-subtle text-center card-color ">
          <p style="color:white; background-color: black; ">{{'app.inicio14' | translate}}</p>
          <a href="../#/plantilla/32" style="text-decoration:none; color: black;">
            
                <img src="./../../assets/img/averroes-substancia.webp" 
                  class=" img-fluid rounded-start" alt="..."
                  width="300px" height="200px"> 
           
          </a>
        </div> 


        <br>
        <div class="card text-dark mb-3 border-primary-subtle text-center card-color">
          <p style="color:white; background-color: black; ">{{'app.inicio14' | translate}}</p>
          <a href="../#/plantilla/0" style="text-decoration:none; color: black;">
           
                <img src="./../../assets/img/FILOSOFÍA-MÍSTICA-Y-RELIGIÓN-EN-EL-ISLAM-MEDIEVAL.webp"
                  class="img-fluid rounded-start" alt="..." 
                  width="300px" height="200px"> 
           
          </a>
        </div>


        <br>
        <div class="card text-dark mb-3 border-primary-subtle text-center card-color">
          <p style="color:white; background-color: black; ">{{'app.inicio14' | translate}}</p>
          <a href="../#/plantilla/1" style="text-decoration:none; color: black;">
            
                <img src="./../../assets/img/edition-2779-236x354.webp" class="img-fluid rounded-start" alt="..."
                  width="300px" height="150px"> 
            
          </a>
        </div> 

        <!-- <div class="card text-dark mb-3 border-primary-subtle text-center card-color">
          <p style="color:white; background-color: black; ">{{'app.inicio14' | translate}}</p>
          <a href="../#/plantilla/32" style="text-decoration:none; color: black;">
            <div class="row g-0">
              <div class="col-md-3  card card-color" style="border: none;">
                <img src="./../../assets/img/Averroes substancia.webp" class="img-fluid rounded-start" alt="..."
                
                  width="100%" height="80%"> 

                

              </div>
              <div class="col-md-8">

                <div class="card-body">

                  <h2 class="card-title" style="color:white">Averroess, Sobre la substancia del universo [De substantia
                    orbis] </h2>

                </div>
              </div>
            </div>
          </a>
        </div> 


        <br>
        <div class="card text-dark mb-3 border-primary-subtle text-center card-color">
          <p style="color:white; background-color: black; ">{{'app.inicio14' | translate}}</p>
          <a href="../#/plantilla/0" style="text-decoration:none; color: black;">
            <div class="row g-0">
              <div class="col-md-3  card card-color" style="border: none;">
                <img src="./../../assets/img/FILOSOFÍA-MÍSTICA-Y-RELIGIÓN-EN-EL-ISLAM-MEDIEVAL.webp"
                  class="img-fluid rounded-start" alt="..." width="100%" height="100%">
              </div>
              <div class="col-md-8">

                <div class="card-body">
                  <h2 class="card-title" style="color:white">Filosofía, mística y religión en el islam medieval</h2>
                </div>
              </div>
            </div>
          </a>
        </div>


        <br>
        <div class="card text-dark mb-3 border-primary-subtle text-center card-color">
          <p style="color:white; background-color: black; ">{{'app.inicio14' | translate}}</p>
          <a href="../#/plantilla/1" style="text-decoration:none; color: black;">
            <div class="row g-0">
              <div class="col-md-3 card card-color" style="border: none;">
                <img src="./../../assets/img/edition-2779-236x354.webp" class="img-fluid rounded-start" alt="..."
                  width="100%" height="100%">
              </div>
              <div class="col-md-8">

                <div class="card-body">
                  <h2 class="card-title" style="color:white;">Sobre la melancolía, por la diversidad cultural, contra la
                    guerra.</h2>
                </div>
              </div>
            </div>
          </a>
        </div> -->
      </div>
    </section>
    <hr>

    <section>
      <div class="abs-center">
        <div class="row row-cols-1 row-cols-md-1 g-4" style="max-width: 640px;">
          <div class="col">
            <div class="card.jover card text-bg-light">
              <img src="../../assets/img/1.webp" loading="lazy" class="card-img-top" alt="...">
            </div>
          </div>
          <div>
            <hr>
            <h1 class="card-title" style="background-color: transparent; color: black; text-align: center;">
              {{'app.inicio4' | translate}}</h1>
            <hr>
            <p class="card-text">{{'app.inicio5' | translate}}</p>
            <p class="card-text">{{'app.inicio6' | translate}}</p>
            <p class="card-text">{{'app.inicio7' | translate}}</p>
            <p class="card-text">{{'app.inicio8' | translate}}</p>
            <br>
          </div>
        </div>

      </div>
    </section>


  </body>


</div>

</html>
<app-footer></app-footer>