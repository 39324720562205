
<footer>

  <div class="footer-copyright-module ">
    <p style = "font-size: x-large;" class="footer-copyright-text">Profesor Andrés Martínez Lorca
      <button style="background-color: #081c4c; color: white;" mat-icon-button class="example-button-row" aria-label="Example icon-button with share icon" matTooltip="{{'app.icon3' | translate}}" matTooltipPosition="right">
        <a mat-button href="mailto:alorca@fsof.uned.es/" target="_blank">
            <mat-icon style="display:flex; align-self:top;">contact_mail</mat-icon>
        </a>  
      </button>
    </p>
    <p class="footer-copyright-text">© Andrés Martínez Almendro 2023

    <button style="background-color: #081c4c; color: white;" mat-icon-button class="example-button-row" aria-label="Example icon-button with share icon" matTooltip="{{'app.icon4' | translate}}" matTooltipPosition="after">
        <a mat-button href="https://www.linkedin.com/in/andr%C3%A9s-mart%C3%ADnez-almendro-712607124" target="_blank" title="Linkedin de Andrés Martínez Almendro">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77Z"/></svg>
        </a>
      </button>
      <button style="background-color: #081c4c; color: white;" mat-icon-button class="example-button-row" aria-label="Example icon-button with share icon" matTooltip="{{'app.icon5' | translate}}" matTooltipPosition="right">
        <a mat-button href="mailto:andresmalmendro@gmail.com/" target="_blank">
          <mat-icon>contact_mail</mat-icon>
        </a>
      </button>
    </p>

  </div>

</footer>