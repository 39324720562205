<!DOCTYPE html>
<html lang="es">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Masinfo</title>
        <!-- <link rel="canonical" href="https://www.andresmlorca.com/masinfo"> -->
    </head>
    <body>
        <div class="card" >
            <img src="./../../assets/img/1Plaza_Mayor_de_Salamanca_después1_de_la_lluvia.webp" loading="lazy" class="img-fluid rounded-start" height="200px" alt="...">
        </div>
        <br>
        <h1>{{'app.menu7' | translate}}</h1>
        <div class="container">
            <mat-tab-group mat-stretch-tabs backgroundColor="primary">
                <mat-tab label=" {{'app.info1' | translate}}" aria-label="primary">
                    <app-plaza></app-plaza>
                </mat-tab>
                <mat-tab label=" {{'app.info2' | translate}}" aria-label="primary">
                    <app-multimedia></app-multimedia>
                </mat-tab>
                <mat-tab label=" {{'app.info3' | translate}}" aria-label="primary">
                    <app-enlaces></app-enlaces>
                </mat-tab>
                <mat-tab label=" {{'app.info4' | translate}}" aria-label="primary">
                    <app-esquina></app-esquina>
                </mat-tab>
            </mat-tab-group>
        </div>
    </body>
</html>
<app-footer></app-footer>
