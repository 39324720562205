<body>
  <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="titulo">
      <mat-header-cell mat-header-cell *matHeaderCellDef><h1>{{'app.pub4' | translate}}</h1>
        <br><h3> (Los siguientes textos se pueden reproducir indicando el autor y la procedencia.)</h3> </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element"> <a href={{element.src}} target="_blank">  {{element.titulo}} </a></mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</body>