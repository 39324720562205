<body>
    <div class="alert alert-success" role="alert">
        <h1 class="alert-heading">{{'app.crit1' | translate}}</h1>
        <hr style=" height: 5px; background-color: rgb(10, 60, 221); width: 100%;">

        <p><b>Emilio Lledó</b>, filósofo, catedrático emérito de Universidad y miembro de número de la Real Academia Española:
            “En el presente libro se ha acentuado, muy certeramente, ese aspecto de la filosofía griega que, sobre todo desde Sócrates y la sofística, había entrevisto que saber es poder. (...) La interpretación que Martínez Lorca hace del pensamiento socrático y platónico intenta, con lucidez y sympátheia, destacar este problema fundamental de todo el pensamiento griego y, por supuesto, del nuestro. (...)
            Los tres capítulos sobre Aristóteles constituyen una aportación muy importante, para recobrar la auténtica imagen del Aristóteles «perdido» en una tradición de lo que había recobrado la obra genial de Jaeger. (...)
            El libro de Andrés Martínez Lorca responde, inteligentemente, a la óptica de un lector moderno que pretende dialogar con esa «semilla inmortal». (...) Pero el autor del presente libro no se limita a exponer con rigor y erudición el discurso que pusieron en marcha los griegos, sino que ha intercalado con clarividencia y sensibilidad su propia voz. La voz que postula para la filosofía de los griegos, y para la filosofía de todos los tiempos, aquella inquieta pregunta que brota de uno de sus más geniales representantes: ¿Podríamos luchar por saber del mundo, sin saber también de los hombres? ¿Podríamos cultivar la inteligencia, sin creer firmemente que la meta última de ese cultivo es la utópica pero irrenunciable solidaridad?”</p>
        <p>(Prólogo al libro Átomos, hombres y dioses. Estudios de filosofía griega).</p>
        <hr>

        <p><b>José Luis Aranguren</b>, (†) filósofo y Catedrático de Universidad:
                “Me alegra presentar este libro. La aportación que supone a la bibliografía sobre Gramsci no es sólo de valor en nuestro ámbito nacional, donde se le cita casi tanto como se le desconoce. Es un trabajo de cuidadoso análisis textual en su desarrollo histórico, en su devenir a lo largo de toda la actividad literaria del gran marxista italiano. Al estilo historicista del pensamiento de Gramsci se ajusta perfectamente la metodología, crítica y de periodización, adoptada por Andrés Martínez Lorca”.</p>
        <p>(Prólogo al libro El problema de los intelectuales y el concepto de cultura en Gramsci).</p>
        <hr>

        <p><b>Carlos García Gual</b>, helenista y catedrático de Universidad:
            “La perspectiva adoptada es la de un historiador de la filosofía que no desdeña el detalle filológico, y pretende ser crítico con los autores antiguos, situándolos en su época, y también con sus intérpretes modernos...Las notas bibliográficas están cuidadas, académicamente”.</p>
        <p>(Crítica del libro Átomos, hombres y dioses, publicada en Diario-16, Madrid, 26 de enero de 1989).</p>
        <hr>

        <p><b>Celia Amorós</b>, catedrática de Universidad y escritora feminista:
            “En medio del páramo como telón de fondo de los trabajos sobre filosofía antigua en nuestro país –todavía son muy pocas las publicaciones de calidad-, el libro de Andrés Martínez Lorca, Átomos, hombres y dioses. Estudios de filosofía griega emerge con toda dignidad intelectual. (...) Especialmente, el autor tiene un completo dominio del estado de la investigación y de la reconstrucción filológica que tan meritoriamente se está llevando a cabo en Nápoles en torno a los Papiros de Herculano, fuente del epicureísmo, que constituye uno de los núcleos fundamentales de su atención...Pero maneja asimismo con soltura la gran tradición filológica alemana y la anglosajona, y en la minuciosa finura microhermenéutica de que hace gala en el análisis de los textos puede identificarse el magisterio de Emilio Lledó, quien en su Prólogo pone expresivamente de manifiesto su sympátheia y estima por el modo de hacer del profesor Martínez Lorca. (...)
            En su conjunto, pues, el libro del profesor Martínez Lorca presenta unas sugerentes líneas de investigación y una estimulante panorámica de problemas de filosofía antigua con implicaciones actuales”.</p>
        <p>(Crítica publicada en la Revista A Distancia de la UNED, 1989).</p>
        <hr>

        <p><b>Antonio Alegre Gorri</b>, profesor de Filosofía en la Universidad Central de Barcelona:

            “El presente libro aborda, con penetración y aportaciones creativas, aspectos importantes del mundo griego y de su filosofía. En su conjunto se trata de una obra muy meritoria. (...)
            Recomiendo vivamente la lectura de esta obra sobre los griegos antiguos y su filosofía. Constato con enorme placer que la obra de Andrés Martínez Lorca está influenciada por Gramsci, M. Sacristán y Emilio Lledó; (...) por ello me gusta el libro de Martínez Lorca, pero también por su rigor, erudición y capacidad creativo-investigadora, así como por su cuidado estilo”.</p>
        <p>(Crítica del libro Átomos, hombres y dioses, en Anthropos, Revista de Documentación Científica de la Cultura, Barcelona, nº 98-99, 1989).</p>
        <hr>

        <p><b>Carlos Gurméndez</b>(†) filósofo y escritor:
            “La lectura de estos ensayos entusiasma por la rara conjunción de una interpretación histórico-dialéctica de la filosofía y una vasta erudición crítica. Por ejemplo, en la introducción a esta obra, Andrés Martínez Lorca analiza la conquista árabe de la Península y la caída de la monarquía visigoda debida a las desigualdades de una estructura social feudal y a la explotación inicua del campesinado. (...) Un hecho importante fue la colaboración de los judíos en la creación cultural árabe”.</p>
        <p>(Crítica del libro Ensayos sobre la filosofía en al-Andalus, diario EL PAÍS, 23 de septiembre de 1990).</p>
        <hr>

        <p><b>María Isabel Fierro</b>, arabista y Profesora de Investigación en el Instituto de Filología del C.S.I.C.
            “La intención del coordinador ha sido ofrecer un «variado mosaico de investigaciones sobre la filosofía en al-Andalus» y «un enfoque plural del pensamiento andalusí»: lo ha conseguido plenamente.. (...) El intento de síntesis que ahora nos ofrece A. Martínez Lorca constituye una importante aportación muy de agradecer, enriquecida por una útil bibliografía básica.
            Me ha parecido especialmente atinado insistir en la necesidad de estudiar conjuntamente a los filósofos musulmanes y judíos en al-Andalus (p. 36) y en la estrecha conexión entre medicina y filosofía en al-Andalus (p. 38), aunque me pregunto si en este punto el caso de al-Andalus fue diferente del resto del mundo islámico”.</p>
        <p>(Crítica del libro Ensayos sobre la filosofía en al-Andalus publicada en la Revista Al-Qantara, XII (1991), pp. 301-302.)</p>
        <hr>

        <p><b>Fernand Van Steenberghen</b>, (†) catedrático en la Universidad de Lovaina y eminente medievalista:
            “Professeur à l’Universidad nacional de educación a distancia (Madrid), M.L. a groupé, dans ce volume, 18 études publiées au cours du dernier demi-siècle, dans divers périodiques ou recueils, par les meilleurs arabisants d’Espagne, auxquels il a joint Roger Arnaldez, l’éminent arabisant français. (...)
            Ces monographies sont précedées par une importante introduction (pp. 7-82) du Prof. Martínez Lorca: La filosofía en al-Andalus. Una aproximación histórica. Cette introduction est suivie d’une Bibliografía básica (pp. 83-93) d’un vif interet. (...)
            Ce volume sera préciéux pour quiconque s’intéresse aux penseurs arabes et juifs qui ont illustré l’Espagne médiévale”.</p>
        <p>(Crítica del libro Ensayos sobre la filosofía en al-Andalus, publicada en la Revue Philosophique de Louvain, Louvain-la- Neuve (Bélgica), tome 90, mayo de 1992).</p>
        <hr>

        <p><b>Jacobo Muñoz</b> (†), catedrático de la Universidad Complutense de Madrid:
            “Formado en la escuela de ese gran maestro que es Emilio Lledó, y especialista él mismo en filosofía antigua y medieval, Martínez Lorca compiló hace tiempo un interesante volumen sobre la filosofía en al-Andalus. Los importantes trabajos sobre el cordobés Ibn Rusd, el Averroes de los latinos, que ahora reúne constituyen, considerados como un todo prismático, una aportación de primer orden al conocimiento de uno de los españoles que «mayor influencia ha ejercido en todo lo largo de la historia sobre el pensamiento humano», en palabras de Juan Vernet. Su lectura deparará, sin duda, sorpresas al lector interesado en esa rara superposición de estratos en que se configura nuestra historia, globalmente considerada”.</p>
        <p>(Crítica del libro Al encuentro de Averroes, en ABC literario, 17 de diciembre de 1993).</p>
        <hr>

        <p><b>Eugenio Cabezas</b>, redactor del diario SUR (Málaga):
            “MÁLAGA INMORTALIZADA
            La prensa diaria es el termómetro de la vida. Con el paso de los años, lo que aparece en un periódico no tiene por qué quedar desfasado, sino que puede cobrar más valor aún del que tenía en el momento de aparecer. Esto es lo que ocurre con el libro Personajes y escenas de Málaga del catedrático de Filosofía de la UNED y director del centro asociado de esta institución en Málaga, Andrés Martínez Lorca. Esta obra, que fue presentada ayer en el Ateneo de Málaga, recopila el conjunto de entrevistas y crónicas que Martínez Lorca publicó en SUR entre los años 1983 y 1984.
            En el acto, el profesor Martínez Lorca estuvo acompañado de amigos y compañeros de toda la vida, así como de distintas personalidades del mundo de la cultura y la política local. (...) El libro ha sido editado por el Centro de Ediciones de la Diputación Provincial (CEDMA), y a la presentación acudió su director, Félix Martín”.</p>
        <p>(Diario SUR, Málaga, 22 de julio de 2005, p. 11).</p>
        <hr>

        <p><b>Lola Josa</b>, Profesora de la Universidad de Barcelona:
            “Abu-l-Walid Ibn Rusd (Averroes, en su forma latina) dio los primeros pasos hacia la filosofía y la ciencia moderna al explicar el mundo natural desde la ciencia objetiva y no desde la teología; al asentar la biología y la ética como senderos para la sabiduría, ya que, según él, la verdad sólo puede alcanzarse por medios humanos y naturales.
            Con este principio básico de su metafísica, Averroes se nos convierte en un filósofo próximo a nosotros. Y todo ello gracias a su concepción del saber, a su teoría sobre el intelecto que tanta fama y reconocimiento le comportó en la Edad Media occidental. Por este motivo, Trotta no podía dejar de enriquecer su magnífica colección Al-Andalus con la edición de los fragmentos más fundamentales de los tres comentarios averroístas sobre la facultad racional: «Compendio del libro Sobre el alma de Aristóteles», «Comentario Medio al libro Sobre el alma de Aristóteles» y «Gran Comentario al libro Sobre el alma de Aristóteles», reunidos, por vez primera, bajo un mismo título. Pero es que, además, de la mano de Andrés Martínez Lorca, responsable de la colección, de la edición y del imprescindible estudio introductorio, el lector se adentra en la filosofía de Averroes de una manera rigurosa y, a su vez, amena y esclarecedora, para llegar a la asombrosa conclusión de que el filósofo árabe significó verdaderamente «progreso y ruptura» en el pensamiento medieval”.</p>
        <p>Revista El Ciervo, nº 692, Barcelona, noviembre de 2008</p>
        <hr>
        
        <p><b>Nieves Paradela</b>, Profesora de la Universidad Autónoma de Madrid y arabista:
            “Se reúnen en este volumen diecisiete trabajos del profesor Martínez Lorca, publicados todos (salvo uno, inédito) entre 1991 y 2007. Se trata pues de una obra de carácter antológico y recapitulativo (…). En este caso, la concentración en el asunto esencial (el pensamiento andalusí y sus más destacados representantes) y la calidad de los trabajos hacen que el resultado sea convincente y, por ello, el libro resulta de recomendable lectura para los estudiosos de la historia de la filosofía andalusí y también — aunque ello quede enmascarado en el título y subtítulo elegidos — para los interesados en la historia del moderno arabismo español.
            Leyéndolo asistimos al despliegue de un número tal de cuestiones que, insertas en su contexto histórico y analizadas con detalle en su desarrollo, provocan una muy justificada admiración hacia aquel al-Andalus (…).
            Con un lenguaje accesible y un excelente aparato crítico, el libro del profesor Martínez Lorca proporciona una rigurosa visión de conjunto de las más destacadas cuestiones que produjo el pensamiento andalusí”.</p>
        <p>(Crítica del libro Maestros de Occidente. Estudios sobre el pensamiento andalusí, publicada en la Revista Awraq. Estudios sobre el mundo árabe e islámico contemporáneo, volumen XXV (2008), pp. 315-317.)</p>
        <hr>

        <p><b>Maria Cabré Duran</b>, Profesora de la Universitat Autònoma de Barcelona
            “"Davant d’aquesta conjuntura i de la necessària brevetat d’un text de divulgació sobre filosofia, fer un esbós de la filosofia medieval, de quinze segles de producció de pensament plens de canvis culturals i històrics de gran envergadura, és ja un gran mèrit. Però, a més, cal aplaudir la claredat de l’exposició i la capacitat de l’autor de compendiar informació, qui, a pesar de veure’s empès a escollir personatges i conceptes, és capaç d’oferir un esquema conceptual de les idees fonamentals de cada pensador, acompanyades adequadament de fragments de les fonts primàries d’aquest".</p>
        <p>(Reseña del libro Filosofía Medieval: de al-Farabi a Ockham, Revista Enrahonar. Quaderns de Filosofia, nº 56, 2016, pp. 123-125). <a href="./../../../assets/pdf/Crítica de María Cabré Durán sobre el libro Filosofía Medieval.pdf" target="_blank" rel="noopener noreferrer">(pdf)</a> </p>
        <hr>

        <p><b>Salvador López Arnal</b>,  Profesor-tutor de Matematicas de la UNED
            “Una excelencia filosófica que conviene no perderse. (…) Estamos ante otro gran libro de uno de nuestros grandes arabistas más destacados, filósofo de una pieza, de la cabeza a los pies, pasando por un corazón siempre comprometido con causas de justicia, equidad, fraternidad y libertad”. </p>
        <p>(Crítica del libro Hacia un nuevo Averroes. Naturalismo y crítica en el pensador andalusí que revolucionó Europa, Madrid, UNED, 2017). <a href="https://rebelion.org/una-excelencia-filosofica-que-conviene-no-perderse/" target="_blank" rel="noopener noreferrer">(Enlace)</a> </p>
        <hr>
        <p><b>ABC</b>,  Periódico español. La edición se caracteriza por una gran fidelidad interpretativa y lingüística respecto al texto original, algo que los autores han tenido en cuenta para que el influyente texto filosófico de Averroes sobre la estructura del universo sea comprendido de forma inequívoca. Esta traducción se basa en el manuscrito más antiguo conservado en la Biblioteca Nacional, que está comentado por Álvaro de Toledo, un clérigo erudito descubierto por el arabista jesuita P. Manuel Alonso. </p>
        <p>(Crítica del libro Averroes, sobre la substancia del universo [De Substantia Orbis]). <a href="https://www.abc.es/cultura/libros/averroes-sobre-substancia-universo-substantia-orbis-20230607091036-nt.html" target="_blank" rel="noopener noreferrer">(Enlace)</a> </p>
        <hr>

        <p><b>José Solana Dueso</b>, catedrático de Filosofía de la Universidad de Zaragoza.</p>
            
            
            <p>"El libro de los profesores Andrés Martínez Lorca y Pedro Roche destaca por ofrecer una visión clara y rigurosa de la filosofía política de Aristóteles, que el genio de Tómas de Aquino supo
                reconocer y asimilar para transformarla en la base de su propio pensamiento. Mérito suyo fue elevar a la primera posición de la agenda filosófica del siglo XIII a un autor relegado hasta ese momento
                que ponía en primer plano la figura del ciudadano en la vida política.
            </p>
            <p>Mención aparte merece la amplia introducción (50 páginas) a cargo de Martínez Lorca, concebida como una guía de lectura, un instrumento excelente y muy valioso que consta de seis capítulos.</p>
            <p>Lo expuesto permite concluir que el presente libro constituye una introducción clara y rigurosa al pensamiento político de Tomás de Aquino. En ello consiste su mérito y la razón para recomendar vivamente su lectura y estudio."</p>
        <p>(Reseña de la revista <i>  ÉNDOXA </i>
            
             del libro Tomás de Aquino, Exposición de la Política de Aristóteles, edición de Pedro Roche Arnas y Andrés Martínez Lorca, Madrid, Editorial UNED, abril de 2019.) <a href="./../../../assets/pdf/Reseña de Solana Dueso.pdf" target="_blank" rel="noopener noreferrer">(pdf)</a> </p>
    </div>
</body>
    
    
    
