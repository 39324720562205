import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fuentes',
  templateUrl: './fuentes.component.html',
  styleUrls: ['./fuentes.component.css']
})
export class FuentesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
