
import {Component} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';


/**
 * @title Table with expandable rows
 */
@Component({
  
  selector: 'app-articulos',
  styleUrls: ['./articulos.component.css'],
  templateUrl: './articulos.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ArticulosComponent {
  dataSource = ELEMENT_DATA;
  columnsToDisplay = ['titulo']; /* , 'weight', 'symbol', 'position']; */
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: LibrosElement | null;
}

export interface LibrosElement {
  titulo: string;
}

const ELEMENT_DATA: LibrosElement[] = [
 
  {titulo: '“Averroes, renovador del pensamiento medieval”, Sophía-Filosofía Medieval. Muy Historia, nº 36, 2023, pp. 92-105.'},
  {titulo: '"La recepción de la Ética aristotélica en Averroes y su impacto en el mundo latino medieval", Revista ÉNDOXA: Series Filosóficas, nº 48, 2021, pp. 15-45, UNED, Madrid.'},
  {titulo: '"Ibn Gabirol en el milenario de su nacimiento: el filósofo sefardí que unió neoplatonismo y judaísmo", La Ciudad de Dios-Revista Agustiniana, volumen 234- nº 2, mayo-agosto 2021, pp. 555-576, Guadarrama (Madrid).'},
  {titulo: '"Libertad y creatividad en la obra de Cristóbal", Memorias de Ronda. Revista de Historia y Estudios Rondeños, Época 2ª, nº 9, mayo 2021, pp. 38-41.'},
  {titulo: '“Maimónides, el sabio andalusí que renovó el judaísmo”, en Arqueología & Historia, 2016 (nº 9), 4 pp.'},
  {titulo: '“Crítica y renovación en tres pensadores árabes contemporáneos” La Ciudad de Dios, 2016, 19 pp.'},
  {titulo: '“De la cultura integral a la diversidad cultural: reflexiones actuales a partir de Gramsci”, en Gramsci y la sociedad intercultural, G. Pala, A. Firenze y J. Mir García, eds., Barcelona, Montesinos, 2014, 13 pp.'},
  {titulo: '“Los rostros del Comentador. Averroísmo y antiaverroísmo en Francia durante el siglo XIII”, en Encrucijada de culturas: Alfonso X y su tiempo, E. González Ferrín, Coord., Sevilla, Tres Culturas, 2014, 34 pp.'},
  {titulo: '“Baltasar Gracián y la crítica política: en la estela del antimaquiavelismo”, en Maquiavelo en España y Latinoamérica (Del siglo XVI al XXI), M. González García y R. Herrera Guillén, Coords., Madrid, Tecnos, 2014, 21 pp.'},
  {titulo: '“La crítica de al-Ghazali al racionalismo filosófico islámico: revisión de una polémica histórica”, en Al-Ghazali, Biblioteca Ambrosiana de Milán, Roma, Bulzoni Editore, 2013, 30 pp.'},
  {titulo: '“Mahmud Ali Makki, erudito egipcio e ilustre hispanista”, El País, 13 de agosto de 2013, 1 p.'},
  {titulo: '"Communitas liberorum. En torno a Sententia libri Politicorum de Tomás de Aquino”, en PENSAMIENTO, vol. 51, , nº 199, 1995, pp. 89-100.'},
  {titulo: '"Del espíritu de la letra a la crítica política en el filósofo hispano-musulmán Ibn Hazm de Córdoba”, La Ciudad de Dios, vol. CCVIII, 1995, pp. 67-82.'},
  {titulo: '"El Método Escolástico: desarrollo histórico y evolución doctrinal”, en PENSAMIENTO, Universidad Pontificia de Comillas, vol. 59, nº 225, septiembre-diciembre de 2003, pp. 431-452.'},
  {titulo: '"La recepción del legado filosófico árabe en la Escolástica y el Renacimiento", en Las huellas del Islam, Fátima Roldán y Mª Mercedes Delgado, eds., Huelva, Universidad de Huelva, 2008, pp. 97-120.'},
  {titulo: '"Lucrecio: una crítica ilustrada a la religión popular”, en Epicureismo Greco e Romano. Atti del Congresso Internazionale, edición de G. Giannantoni y M. Gigante, ELENCHOS, Nápoles, Bibliopolis, 1996, vol II, pp. 851-864; reproducido en ÉNDOXA, nº 3, 1994, pp. 165-177.'},
  {titulo: '“ El concepto de civitas en la teoría política de Tomás de Aquino ”, en VERITAS, Revista de la Pontificia Universidade Católica do Rio Grande do Sul, Porto Alegre ( Brasil ), junio de 1993, pp. 253- 261; reproducido en Analogía Filosófica, México D.F., 1996, X, pp. 139-150.'},
  {titulo: '“ La concepción de la historia de la filosofía en Aristóteles”, en ÉNDOXA, nº 1, 1993, pp. 21-36.'},
  {titulo: '“¿ Cómo fue posible la germinación de la filosofía en al-Andalus?” , en Analogía Filosófica, Revista de Filosofía, nº 2, México D.F., 1992, pp. 163-182; reproducido en MEDIAEVALIA. Textos e Estudos, Oporto ( Portugal ), nº 5-6, 1994, pp. 173-189; reproducido también en ÉNDOXA, nº 10, 1998, pp. 205-220.'},
  {titulo: '“A la sombra de la guerra: paz y religiosidad en un mundo en crisis”, La Ciudad de Dios, Real Monasterio de El Escorial, CCXVI, mayo-diciembre de 2003, pp. 621-635.'},
  {titulo: '“Adolfo Sánchez Vázquez, filósofo hispanomexicano”, El País, 15 de julio de 2011, 1 p.'},
  {titulo: '“Adolfo Sánchez Vázquez: el legado fecundo de un filósofo marxista y el testimonio ejemplar de un republicano del exilio”, Isegoría, 2011 (julio-diciembre), 3 pp.'},
  {titulo: '“Averroes y la revolución intelectual del siglo XIII”, en La Ciudad de Dios, vol. CCXII, nº 2, mayo-agosto de 1999, pp. 397-413.'},
  {titulo: '"Averroes, Europa y la Modernidad”, en Diario EL PAIS, suplemento «Babelia», Madrid, 28 noviembre de 1998.'},
  {titulo: '“Averroes, sobre las facultades locomotriz y desiderativa en el Taljîs del De Anima”, La Ciudad de Dios, Real Monasterio de El Escorial, CCXXI (2008), pp. 201-221.'},
  {titulo: '“Averroes, Tafsîr del De Anima: Sobre el intelecto”, en ÉNDOXA, Facultad de Filosofía de la UNED, nº 17, 2003, pp. 9-61'},
  {titulo: '“Averroes, un pensador cuyo legado sigue vivo”, en Encuentros Averroes (Casa Árabe-UNESCO), 2011, 2 pp.'},
  {titulo: '“De la cultura integral a la diversidad cultural: reflexiones actuales a partir de Gramsci”, La Lámpara de Diógenes (Universidad Autónoma de Puebla, México), 2010 (nº 20-11), 13 pp.'},
  {titulo: '“Del Dios aristotélico al Dios judío. Reflexiones sobre los límites del aristotelismo en Maimónides”, en Sobre la vida y obra de Maimónides. I Congreso Internacional (Córdoba, 1985), Jesús Peláez del Rosal, editor, Córdoba, Ediciones El Almendro, 1991, pp. 359-374.'},
  {titulo: '“El antiguo Egipto en la cultura griega”, en Revista del Instituto Egipcio de Estudios Islámicos en Madrid, vol. XXXIII, Madrid, 2001, pp. 97-110.'},
  {titulo: '“El pensamiento crítico de Adolfo Sánchez Vázquez”, en ATENEO DEL NUEVO SIGLO, Revista del Ateneo de Málaga, nº 7, 2005, pp. 34-36.'},
  {titulo: '“Entrevista [de Salvador López Arnal] a Andrés Martínez Lorca”, en Papeles de Relaciones Ecosociales y Cambio Global, 2010, 8 pp.'},
  {titulo: '“Filosofía, religión y mística en la Risâla de Ibn Tufayl”, La Ciudad de Dios, enero-abril de 1999, vol. CCXII, nº 1, enero-abril de 1999, pp. 215-229.'},
  {titulo: '“Hegel y la historiografía filosófica”, en ÉNDOXA, Facultad de Filosofía de la UNED, nº 16, 2002, pp. 9-29.'},
  {titulo: '“Ibn Gabirol y el inicio de la filosofía en al-Andalus”, en AA.VV., Pensadores judíos. De Filón de Alejandría a Walter Benjamin, Palma de Mallorca, Lleonard Muntaner Editor, 2011, 13 pp.'},
  {titulo: '“Ibn Hazm, filósofo: los temas centrales de su pensamiento”, La Ciudad de Dios, 2009, 44 pp.'},
  {titulo: '“Ibn Rušd como historiador de la filosofía”, en Bulletin of the Faculty of Arts, Cairo University Press, 1992, nº 54, pp. 101-120. Reproducido en La Ciudad de Dios, Revista del Monasterio de El Escorial, CCVI, 1993, pp. 847-857.'},
  {titulo: '“Ibn Rushd: el filósofo andalusí que revolucionó Europa”, Jábega. Revista de la Diputación Provincial de Málaga, 97 (2008), pp. 83-92.'},
  {titulo: '“Isidoro Requena, el maestro que enseñaba a pensar junto al Mediterráneo” Cifra Nueva (Universidad de Los Andes, ULA, Venezuela), 2012 (julio-diciembre), 3 pp.'},
  {titulo: '“La concepción de la política en Tomás de Aquino”, en Actas del I Congreso Nacional de Filosofía Medieval, Zaragoza, SOFIME, 1992, pp. 381-387.'},
  {titulo: '“La crítica de Al-Gazzali al racionalismo filosófico islámico y la refutación de Averroes”, La Ciudad de Dios, 2012, 28 pp.,'},
  {titulo: '“La Ética de Averroes: acerca de la felicidad y la amistad”, Al-Mulk. Anuario de Estudios Arabistas, Real Academia de Córdoba, II Época, 7 (2007), pp. 69-87.'},
  {titulo: '“La noética de Averroes en el Gran Comentario al Libro sobre el alma de Aristóteles”, La Ciudad de Dios, Real Monasterio de El Escorial, CCXV, 2002, pp. 815-871.'},
  {titulo: '“La noética de Averroes: hacia la autonomía científica de la psicología”, ensayo en Mente y Cerebro, nº17, Barcelona, 2005, p. 96.'},
  {titulo: '“La nueva concepción de la Historia en Ibn Jaldún”, en Al-Mulk. Anuario de Estudios Arabistas, Instituto de Estudios Califales de la Real Academia de Córdoba, II época, nº 6, 2006, pp. 21-33.'},
  {titulo: '“La Reforma Almohade: del impulso religioso a la política ilustrada”, en Espacio, Tiempo y Forma. Serie III. Historia Medieval, Revista de la Facultad de Geografía e Historia de la UNED, nº 17, 2004, pp. 399-413.'},
  {titulo: '“La semilla inmortal de Don Emilio García Gómez”, en Revista del Instituto Egipcio de Estudios Islámicos en Madrid, vol. XXVIII, Madrid, 1996, pp. 83-100.'},
  {titulo: '“Los almohades y el pensamiento andalusí”, en Bulevar, Málaga, nº 9, 1990, pp.11-12.'},
  {titulo: '“Mohamed Á. Yabri, renovador del pensamiento árabe “, El País, 9 de mayo de 2010, 1 p.'},
  {titulo: '“Mohamed Arkoun, intelectual árabe e innovador islamólogo”, El País, 18 de septiembre de 2010, 1 p.'},
  {titulo: '“Un pionero en el laberinto. Esbozo de biografía intelectual de Don Miguel Asín. Años de formación y primeras publicaciones, 1891-1913” , ÉNDOXA, nº 6, 1996, pp. 37-52.'},
  {titulo: '“Una indagación sobre la melancolía”, en la Revista La Ciudad de Dios, Real Monasterio de El Escorial, CCXIX, 2006, pp. 521-539.'},
  {titulo: '“Volver a Asín”, en Homenaje a Don Miguel Asín Palacios, ÉNDOXA, nº 6, 1996, pp. 7-10.'}
];




