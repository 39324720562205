<!DOCTYPE html>
<html lang="es">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>al-Andulus</title>
        <!-- <link rel="canonical" href="https://andresmlorca.com/alandalus"> -->
    </head>
    <body>
        <div class="container">
            <body>
                <div class="card">
                    <img src="./../../assets/img/AA1024px-Puente_romano_de_Córdoba_2.webp" class="img-fluid rounded-start"
                        alt="...">
                </div>
                <br>
                <h1> {{'app.aland5' | translate}}</h1>
                <h4>Ofrezco una actualización de las fuentes historiográficas así como de las ediciones y traducciones de
                    los filósofos andalusíes en mi reciente libro La filosofía en al-Andalus, Córdoba, Almuzara, 2017, pp.
                    93-111.</h4>
                <mat-tab-group mat-stretch-tabs backgroundColor="primary" style="background-color: #4054b4; color: white;">
                    <mat-tab label="{{'app.aland2' | translate}}" aria-label="primary" backgroundColor="secondary">
                        <app-estudios></app-estudios>
                    </mat-tab>
                    <mat-tab label="{{'app.aland3' | translate}}" aria-label="primary">
                        <app-fuentes></app-fuentes>
                    </mat-tab>
                    <mat-tab label="{{'app.aland4' | translate}}" aria-label="primary">
                        <app-obras></app-obras>
                    </mat-tab>
                </mat-tab-group>
            </body>
        </div>
    </body>

</html>
<app-footer></app-footer>