<hr>
<h2> Estudios de caracter general</h2>
<hr>
<embed src="../../../assets/pdf/Estudios de caracter general.pdf" type="application/pdf" width="100%" height="1200px" />
<!-- <embed src="../../../assets/pdf/Estudios de caracter general.pdf#toolbar=0&navpanes=0&scrollbar=0&view=FitH" type="application/pdf" width="100%" height="600px" /> -->
<!-- <embed src="../../../assets/pdf/Estudios de caracter general.pdf#toolbar=0&navpanes=0&scrollbar=0&view=FitH" type="application/pdf" width="100%" height="600px" /> -->

<!-- #toolbar=0&navpanes=0&scrollbar=0 -->


<!-- https://programacion.net/articulo/como_embeber_un_documento_pdf_en_una_pagina_web_1931 -->
