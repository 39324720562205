import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alandalus',
  templateUrl: './alandalus.component.html',
  styleUrls: ['./alandalus.component.css']
})
export class AlandalusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
