<!DOCTYPE html>
<html lang="es">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Sobre</title>
        <!-- <link rel="canonical" href="https://www.andresmlorca.com/sobre"> -->
    </head>
    <body>
        <div class="card" >
            <img src="./../../assets/img/European_art_banner_The_School_of_Athens.webp" class="img-fluid rounded-start" alt="...">
        </div>
        <br>
        <div class="container">
            
            <h1>{{'app.menu6' | translate}}</h1>
            
            <mat-tab-group mat-stretch-tabs backgroundColor="primary">
                <!-- <mat-tab label="{{'app.inicio4' | translate}}" aria-label="primary">
                    <app-presentacion></app-presentacion>
                </mat-tab> -->
                <mat-tab label="{{'app.inicio9' | translate}}" aria-label="primary">
                    <app-saludo></app-saludo>
                </mat-tab>
                <mat-tab label="{{'app.nota1' | translate}}" aria-label="primary">
                    <app-nota></app-nota>
                </mat-tab>
                <mat-tab label="{{'app.activ1' | translate}}" aria-label="primary">
                    <app-actividades></app-actividades>
                </mat-tab>
                <mat-tab label="{{'app.crit1' | translate}}" aria-label="primary">
                    <app-criticas></app-criticas>
                </mat-tab>
            </mat-tab-group>
        
        </div>
    </body>
</html>
<app-footer></app-footer> 

