

  <div class="accordion" id="accordionPanelsStayOpenExample">
    <hr>
    <h1>{{'app.info1' | translate}}</h1>
   
    <div class="accordion-item">
      <h2 class="accordion-header" id="panelsStayOpen-headingOne">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
          Diario Sur
        </button>
      </h2>
      <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
        <div class="accordion-body">
            Las crónicas urbanas y entrevistas que se publicaron con éxito en el diario SUR, representan un mosaico de la sociedad malagueña de los años 80, en plena transición.
            Todo este material dio lugar al libro <a href="../#/plantilla/12" target="_blank"> Personajes y escenas de Málaga</a>. En ellas se recuperan tanto la Málaga popular, olvidada por el franquismo, como la brillante cultura literaria de la Generación del 27 cuyos miembros, alejados de su tierra por
            el exilio, eran desconocidos para la mayoría de la gente. También conectan estas páginas con la tradición ilustrada y liberal de la Málaga decimonónica, al recordar a las nuevas
            generaciones el papel de primer orden que desempeñó entonces nuestra provincia en la vida política y económica del país. "Málaga es, sobre todo, una ciudad abierta y la única en
            España, junto con Cádiz, que tiene mundo", afirma José Antonio Muñoz Rojas en este libro, y de ello dan fe los muy diversos personajes aquí recuperados por ANDRÉS MARTINEZ LORCA y
            dibujados con maestría por SALVADOR MORENO PERALTA. <a href="./../../../assets/pdf/Prologo a Personajes y escenas de Malaga.pdf" target="_blank"> Aqui puede leer el Prólogo de Personajes y escenas de Málaga</a>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
          Rebelión
        </button>
      </h2>
      <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
        <div class="accordion-body">
          <a href="https://rebelion.org/autor/andres-martinez-lorca/" target="_blank" >Artículos</a> publicados durante los ultímos años y de diferente temática.
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="panelsStayOpen-headingThree">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
          El País
        </button>
      </h2>
      <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
        <div class="accordion-body">
           Crónicas y necrológicas <a href="https://elpais.com/autor/andres-martinez-lorca/" target="_blank" >publicadas  </a>  en el diario El País.
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="panelsStayOpen-headingThree">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
          La Opinión de Málaga
        </button>
      </h2>
      <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
        <div class="accordion-body">
          <a href="https://www.laopiniondemalaga.es/cultura-espectaculos/2022/11/21/cristobal-aguilar-artista-alejo-mercado-78859687.html" target="_blank"> Cristóbal Aguilar, el artista que se alejó del mercado y se ancló en la sociedad</a>  
        </div>
      </div>
      <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
        <div class="accordion-body">
          <a href="./../../../assets/pdf/Las luchas estudiantiles en Málaga.pdf" target="_blank" >Las luchas estudiantiles en Málaga fueron el germen del movimiento democrático en la sociedad</a>  
        </div>
      </div>
    </div>
    <div class="accordion-item">
        <h2 class="accordion-header" id="panelsStayOpen-headingThree">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
            Pregones
          </button>
        </h2>
        <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
          <div class="accordion-body">
            <ul class="list-group list-group-flush">
                <li class="list-group-item"><a href="./../../../assets/pdf/PREGÓN DE LAS FIESTAS DE DALÍAS 2016.pdf" target="_blank" rel="noopener noreferrer">Pregón en las Fiestas de Dalías (Almería)</a></li>
                <li class="list-group-item"><a href="./../../../assets/pdf/Mollina.pdf" target="_blank" rel="noopener noreferrer">Pregón de la Feria de la Vendimia en Mollina (Málaga)</a></li>
            </ul>
          </div>
        </div>
      </div>
  </div>
  <br>
  