<body>
  <h1>{{'app.activ1' | translate}}</h1>
    <hr>
  <mat-accordion class="example-headers-align">
      <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle class="color">
        <mat-expansion-panel-header>
          <mat-panel-title>
           <b>{{'app.crit2' | translate}}</b>
            
          </mat-panel-title>
          <!-- <mat-icon>get_app</mat-icon> -->
          <mat-icon>keyboard_arrow_down</mat-icon>
          
        </mat-expansion-panel-header>
        <div class="linea">
          <p>Colegio Universitario de Málaga del 1-10-1971 al 31-12-1974. Profesor Adjunto Interino, docencia en “Fundamentos de Filosofía”.</p>
          <p>Facultad de Filosofía y Letras, Universidad de Málaga, del 1-1-1975 al 30-9-1983. Profesor Adjunto Interino y Catedrático Interino, docencia en “Historia de la Filosofía Antigua”.</p>
          <p>Facultad de Filosofía, UNED, del 1-10-1983 al 30-9-2017. Profesor Titular de Universidad y Catedrático de Universidad, docencia en “Historia de la Filosofía Helenística y Medieval” (Licenciatura en Filosofía) hasta el curso 2010-2011, y en “Historia de la Filosofía Española” (Licenciatura en Filosofía) cursos 1985-1986 a 1998-1999; docencia en “Historia de la Filosofía Medieval y Renacentista I” (Grado en Filosofía) y en la asignatura “Nueva hermenéutica de textos en Averroes y Maimónides” (Máster en Filosofía) desde el curso 2010-2011.</p>
          <p>Centro Asociado de Madrid de la UNED. Profesor-Tutor desde el curso 1995-1996 hasta el 2002-2003 para la asignatura “Historia de la Filosofía Antigua y Medieval” de la Facultad de Geografía e Historia.</p>
          <p>Centro Asociado de Madrid de la UNED. Profesor-Tutor desde el curso 1997-1998 hasta el curso 2003-2004 para la asignatura “Historia de la Filosofía Helenística y Medieval” de la Facultad de Filosofía.</p>
          <p>Profesor- Tutor de Apoyo en Red (TAR) de la UNED para la asignatura “Historia de la Filosofía Medieval y Renacentista I” durante los cursos 2011-2012 y 2012-2013.</p>
        </div>

      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle class="color">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>{{'app.crit3' | translate}}</b>
            </mat-panel-title>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </mat-expansion-panel-header>
          <div class="linea">
              <p>Participación en la I Semana Andaluza de Filosofía, organizada por la Universidad de Málaga y celebrada en Málaga durante el mes de julio de 1979 con una Comunicación titulada "El dilema libertad-necesidad en Demócrito y Epicuro".</p>
              <p>Participación en el I Congreso Internacional sobre la vida y obra de Maimónides, organizado por el Ministerio de Cultura y la Universidad de Córdoba y celebrado en Córdoba del 8 al 11 de septiembre de 1985, con una Ponencia titulada "Del dios aristotélico al dios judío. Reflexiones sobre los límites del aristotelismo en Maimónides".</p>
              <p>Participación en el Congreso Internacional "Al-Andalus: tradición, creatividad y convivencia", organizado por el Ministerio de Cultura y la Universidad de Córdoba y celebrado en Córdoba del 18 al 24 de enero de 1987, con una Comunicación sobre el tema "Ibn Rusd, renovador de la filosofía en el mundo medieval".</p>
              <p>Participación en el II Encuentro Hispanomexicano de Filosofía Moral y Política, organizado por la Universidad Internacional Menéndez Pelayo y el CSIC y celebrado en Santander y Madrid durante los días 8 al 19 de septiembre de 1986, con una Comunicación titulada "Hacia una filosofía hispánica".</p>
              <p>Participación en el I Congreso Nacional de Filosofía Medieval, organizado por la Sociedad de Filosofía Medieval y celebrado en Zaragoza en diciembre de 1990, con una Comunicación titulada "La concepción de la política en Tomás de Aquino".</p>
              <p>Participación en el Coloquio Internacional "Diálogo filosófico-religioso entre cristianismo, judaísmo e islamismo durante la Edad Media en la Península Ibérica", organizado por la Société Internationale pour l'Étude de la Philosophie Médiévale (SIEPM) y celebrado en San Lorenzo de El Escorial del 23 al 26 de junio de 1991, con una Comunicación sobre el tema "¿Cómo fue posible la germinación de la filosofía en al-Andalus?"</p>
              <p>Participación en el III Congresso Internazionale della S.I.T.A., organizado por la Sociedad Internacional Tomás de Aquino (SITA) y celebrado en Roma en septiembre de 1991, con una Comunicación titulada "Communitas liberorum. En torno a Sententia libri Politicorum de Tomás de Aquino".</p>
              <p>Participación en el III Congreso Internacional Andalusí, organizado por la Universidad de El Cairo y celebrado en El Cairo del 11 al 14 de enero de 1992, con una Comunicación titulada "Ibn Rusd como historiador de la filosofía".</p>
              <p>Participación en el IX Congreso Internacional de Filosofía Medieval, organizado por la Société Internationale pour l'Étude de la Philosophie Médiévale y celebrado en la Universidad de Ottawa (Canadá) en agosto de 1992, con una Comunicación titulada "El concepto de civitas en la teoría política de Tomás de Aquino".</p>
              <p>Participación en las IV Jornadas Internacionales Medievales, organizadas por la Universidad Nacional Autónoma de México (UNAM) y celebradas en México D.F. en enero de 1993, con la ponencia titulada "Del espíritu de la letra a la crítica política en el filósofo hispanomusulmán Ibn Hazm de Córdoba".</p>
              <p>Participación en el Congreso Internacional “L'Epicureismo Greco e Romano”, organizado por el Centro Internazionale per lo Studio dei Papiri Ercolanesi y la Universidad de Nápoles Federico II y celebrado en Nápoles del 19 al 26 de mayo de 1993, con una Comunicación sobre el tema "Lucrecio: una crítica ilustrada a la religión popular".</p>
              <p>Participación en el II Encuentro Interreligioso de Alcalá, de carácter internacional, organizado por la Universidad de Alcalá y celebrado en Alcalá de Henares del 24 al 26 de noviembre de 1997, con una Ponencia titulada "Paz, cultura y religiosidad".</p>
              <p>Participación en el X Congreso Internacional de Filosofía Medieval, organizado por la Société Internationale pour l'Étude de la Philosophie Médiévale y celebrado en Erfurt (Alemania) del 25 al 30 de agosto de 1997, con una Comunicación titulada "Ibn Tufayl y su concepto de filosofía".</p>
              <p>Participación en el “Convegno Internazionale sul tema Averroè, Commentatore di Aristotele”, organizado por el Istituto Universitario Orientale de Nápoles y celebrado en esta ciudad italiana del 14 al 16 de enero de 1999, con una Ponencia titulada "Averroes as a reader of Aristotle's Peri Psykhés ".</p>
              <p>Participación en el IX Congreso Latinoamericano de Filosofía Medieval celebrado en Porto Alegre (Brasil) del 22 al 26 de septiembre de 2003 con una Ponencia titulada “El lenguaje filosófico de Aristóteles en las versiones grecolatina de Moerbeke y árabo-latina de Escoto”.</p>
              <p>Participación en el Congreso Internacional “Maimonide e il suo tempo” celebrado en la Universidad de Milán (Italia) durante los días 13 y 14 de enero de 2005 con una Ponencia titulada “Maimónides y el pensamiento judío de al-Andalus”.</p>
              <p>Participación en la Conferencia Internacional “Diálogo entre las tres Culturas y nuestra herencia europea” organizada por la Academia Europaea en Toledo del 2 al 5 de septiembre de 2007 con una Comunicación titulada “Said al-Andalusí: una evaluación del papel de la investigación y la ciencia en Al-Andalus y en el mundo”.</p>
              <p>Participación en el XII Congreso Internacional de Filosofía Medieval celebrado en Palermo (Italia) del 16 al 22 de septiembre de 2007 con una Comunicación titulada “Razón práctica en Averroes: acerca de la amistad y la felicidad”.</p>
              <p>Participación en el III Simposio Internacional “La huellas del Islam” organizado por las Universidades de Sevilla y Huelva en Almonaster la Real (Huelva) durante los días 12 y 13 de octubre de 2007 con una Ponencia titulada “La recepción del legado filosófico árabe en la Escolástica y el Renacimiento”.</p>
              <p>Participación en el Simposio Internacional «The Life and Work of Ibn Hazm of Cordoba» celebrado en Estambul (Turquía) del 26 al 28 de agosto de 2008, organizado por el German Orient-Institut in Istanbul, con una Ponencia titulada “Ibn Hazm as a philosopher: the central subjects of his thought”.</p>
              <p>Comunicación presentada con el título “Ibn Rushd’s influence on Scholastic and Renaissance Philosophy” en el «International Ibn Rushd Symposium» celebrado en Sivas (Turquía) del 9 al 11 de octubre de 2008 y organizado por la Cumhuriyet Üniversitesi (Universidad Republicana de Sivas).</p>
              <p>Participación en el Simposio Internacional dedicado a al-Ghazali, organizado por la Academia Ambrosiana de Milán (Italia) el día 7 de noviembre de 2011, con una conferencia titulada “La crítica de al-Ghazali al racionalismo filosófico islámico: revisión de una polémica histórica”.</p>
              <p>Participación en el VII Dies Academicus, Classe di Studi sul Vicino Oriente, organizado por la Academia Ambrosiana de Milán el día 8 de noviembre de 2016 con una conferencia titulada “Al-Andalus, encuentro de culturas y foco de nueva luz en Europa”.</p>
              <p>Participación en el Simposio Internacional “Ethics and Politics in the Arab- Islamic Heritage. Ibn Rushd’s influence in his time and in later times” celebrado en Marrakech (Marruecos) y organizado por la Universidad Cadi Ayyad de Marrakech y el Rawafed Center for Studies and Researches in Maghreb Civilization and Mediterranean Heritage, con una ponencia titulada “La recepción de la Ética aristotélica en Averroes y su impacto en el mundo latino medieval”, pronunciada el día 27 de abril de 2020.</p>
              <p>Participación el día 22 de abril de 2021 en las "Jornadas Ibn Gabirol" celebradas en el Museo Picasso de Málaga con una ponencia sobre el pensamiento filosófico del filósofo sefardí.</p>
          </div>            
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle class="color">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>{{'app.crit4' | translate}}</b>
            </mat-panel-title>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </mat-expansion-panel-header> 
          <div class="linea">
              <p>Seminario impartido en la Facultad de Filosofía de la Universidad Complutense de Madrid sobre el tema "La filosofía de Epicuro como búsqueda de autarquía", durante los meses de abril y mayo de 1987.</p>
              <p>Seminario impartido en la Facultad de Filosofía de la Universidad Complutense de Madrid sobre el tema "El tratado aristotélico Sobre el alma: nuevas perspectivas", durante el mes de febrero de 1988.</p>
              <p>Curso de Doctorado impartido en la Facultad de Filosofía de la UNED durante el curso académico 1987-1988 sobre "La Psicología de Aristóteles".</p>
              <p>Seminario impartido en la Facultad de Filosofía de la Universidad Complutense de Madrid sobre el tema "Algunos problemas de fondo de la Política de Aristóteles", durante los meses de febrero a abril de 1989.</p>
              <p>Curso de Doctorado impartido en la Facultad de Filosofía de la UNED durante el curso académico 1990-1991 con el título "Hacia la autonomía de la filosofía en el pensamiento medieval: la alternativa crítica de Averroes (I). Razón y fe en Fasl al-maqāl".</p>
              <p>Curso impartido en la Facultad de Filosofía y Letras, Sección de Filosofía, de la Universidad Pontificia de Comillas sobre "Filosofía Griega", durante los meses de abril y mayo de 1992.</p>
              <p>Curso de Doctorado impartido en la Facultad de Filosofía de la UNED durante el curso académico 1992-1993 con el título "Hacia la autonomía de la filosofía en el pensamiento medieval: la alternativa crítica de Averroes (II). Crítica a la teología en Tahāfut al-tahāfut ".</p>
              <p>Curso de Doctorado impartido en la Facultad de Filosofía de la UNED durante el curso académico 1994-1995 sobre "Una lectura filosófica del Quijote (I). Parodia, encantamiento y realidad histórica: la estructura del Quijote ".</p>
              <p>Curso de Doctorado impartido en la Facultad de Filosofía de la UNED durante el curso 1995-1996 sobre "Una lectura filosófica del Quijote (II). Humanismo, crítica social y utopía en el Quijote ".</p>
              <p>Coordinador de Doctorado del Departamento de Filosofía de la UNED durante el curso académico 1996-1997.</p>
              <p>Curso de Doctorado impartido en la Facultad de Filosofía de la UNED durante el curso académico 1997-1998 sobre "Filosofía y Mística en Ibn Tufayl".</p>
              <p>Curso de Doctorado impartido en la Facultad de Filosofía de la UNED durante el curso 2004-2005 sobre “La noética de Averroes en el Gran Comentario al libro sobre el alma de Aristóteles”.</p>
              <p>Curso de Doctorado impartido en la Facultad de Filosofía de la UNED durante el curso 2006-2007 sobre “En torno a El collar de la paloma: erotismo, estética e ideología en Ibn Hazm de Córdoba”.</p>
              <p>Curso de Doctorado impartido en la Facultad de Filosofía de la UNED durante el curso 2007-2008 sobre “La recepción medieval de la Ética aristotélica: el Comentario de Averroes a la Ética nicomáquea”.</p>
              <p>Curso de Doctorado impartido en la Facultad de Filosofía de la UNED durante el curso 2008-2009 sobre “El pensamiento crítico de Baltasar Gracián”.</p>
              <p>Curso de Doctorado impartido en la Facultad de Filosofía de la UNED durante el curso 2009-2010 sobre “El pensamiento crítico de Baltasar Gracián”.</p>
              <p>Dirección del Curso “Homenaje a Hegel en el 150 aniversario de su muerte” celebrado del 13 al 27 de noviembre de 1981 y organizado por la Universidad de Málaga, la Diputación Provincial de Málaga y la Caja de Ahorros Provincial de Málaga.</p>
              <p>Dirección del Curso titulado “Al encuentro de Averroes” celebrado en la sede central de la UNED del 22 al 31 de octubre de 1986 y organizado por el Departamento de Historia de la Filosofía.</p>
              <p>Participación en el Seminario “Pasado y presente del mundo judío”, organizado en Tudela por el Centro Asociado de la UNED de Navarra, con una conferencia sobre “El pensamiento judío en la España medieval” celebrada el día 14 de abril de 1989.</p>
              <p>Seminario impartido en el C.A. de la UNED en Las Palmas de Gran Canarias sobre el tema "Un pensamiento en la frontera: génesis y desarrollo de la filosofía en al-Andalus", del 4 al 6 de marzo de 1991.</p>
              <p>Dirección del Curso de Formación del Profesorado "Tres interpretaciones del pensamiento ético-político de Aristóteles", desarrollado en la UNED en los cursos académicos 1992-1993 y 1993-1994.</p>
              <p>Dirección del Curso de Formación del Profesorado "Poder y utopía en el pensamiento español del Siglo de Oro: Cervantes y Quevedo", desarrollado en la UNED durante los cursos académicos 1994-1995 y 1995-1996.</p>
              <p>Participación en el Curso “El mundo clásico en la tradición literaria. I. Edad Media y Renacimiento” organizado por el Departamento de Filología Clásica de la Facultad de Filología de la UNED con una conferencia titulada “La presencia griega en la tradición cultural árabe” pronunciada el día 22 de febrero de 1996.</p>
              <p>Participación en el Curso titulado “Pensamiento Medieval II. Filosofía Judía en España” organizado por la Fundación Fernando Rielo con una conferencia sobre “La filosofía de Maimónides como horizonte de problemas” pronunciada el día 7 de mayo de 1996 en la sede de la Fundación.</p>
              <p>Participación en la “Semana de Homenaje al Maestro D. Emilio García Gómez”, organizada por el Instituto Egipcio de Estudios Islámicos en Madrid con la colaboración de la Real Academia de la Historia y la Real Academia de la Lengua, con una conferencia titulada “La semilla inmortal de Don Emilio García Gómez” pronunciada el día 20 de junio de 1996 en la sede del Instituto Egipcio.</p>
              <p>Dirección del Curso "Razón, naturaleza y sociedad: el pensamiento de Averroes y su influencia", celebrado en Ávila del 20 al 24 de julio de 1998, dentro de los Cursos de Verano de la UNED.</p>
              <p>Participación en el ciclo de conferencias “In memoriam Averroes” organizado por el Ayuntamiento de Córdoba con una conferencia titulada “Averroes y la revolución intelectual del siglo XIII” celebrada el día 10 de diciembre de 1998 en el Alcázar de los Reyes Cristianos de Córdoba.</p>
              <p>Dirección del Curso "Una aproximación al mundo islámico" celebrado en el C.A. de la UNED de Madrid "Gregorio Marañón" del 30 de marzo al 27 de abril del 2000.</p>
              <p>Codirección del Curso Internacional "Egipto y el VIII Milenio" celebrado en el Palacio Ducal de Medina Sidonia (Sanlúcar de Barrameda, Cádiz) del 3 al 7 de julio del 2000, dentro de los Cursos de Verano de la UNED.</p>
              <p>Dirección del Curso de Otoño de la UNED titulado "La cultura en al-Andalus", celebrado en el Palacio Ducal de Medina Sidonia del 7 al 10 de diciembre del 2000.</p>
              <p>Dirección del Curso de Verano de la UNED titulado “La Emigración en España”, celebrado durante los días 1 al 5 de junio de 2002 en el Palacio Ducal de Medina Sidonia (Sanlúcar de Barrameda).</p>
              <p>Dirección del Curso “Las Gnosis del Libro” organizado por el Departamento de Filosofía de la UNED y el Departamento de Filosofía de la Universitat Autònoma de Barcelona y celebrado en la Escuela de Traductores de Toledo, Toledo, del 1 al 3 de mayo de 2003.</p>
              <p>Dirección del Curso de Verano de la UNED titulado “Claves del pensamiento europeo contemporáneo”, celebrado durante los días 7 al 11 de julio de 2003 en el Palacio Ducal de Medina Sidonia (Sanlúcar de Barrameda).</p>
              <p>Participación en el Curso “Tolerancia e Islam” organizado por la Escuela de Traductores, la Facultad de Ciencias Jurídicas y Sociales y la Facultad de Humanidades de la Universidad de Castilla-La Mancha (UCLM) con una conferencia sobre “Tolerancia y convivencia en al-Andalus” pronunciada el día 3 de marzo de 2004 en la Escuela de Traductores de Toledo.</p>
              <p>Director del Curso “El mundo medieval de Maimónides: la aportación a la cultura judía”, organizado en Palma de Mallorca por el Centro Asociado de la UNED en Baleares y el Departamento de Filosofía de la UNED durante los meses de octubre y noviembre de 2004.</p>
              <p>Dirección del Curso de Verano de la Universidad de Málaga titulado “Francisco Giner de los Ríos: su pensamiento e influencia” y celebrado en la ciudad de Ronda del 4 al 8 de julio de 2005.</p>
              <p>Participación en el II Máster Universitario de Comunicación y Cultura, organizado en Málaga por la Universidad de Málaga y “Ámbito Cultural” de El Corte Inglés, con un Curso titulado “Tres perspectivas filosóficas: Aristóteles, Averroes y Russsell”, desarrollado durante los días 18, 21 y 25 de mayo de 2005.</p>
              <p>Participación en el III Máster Universitario de Comunicación y Cultura organizado en Málaga por la Universidad de Málaga y “Ámbito Cultural” de El Corte Inglés con un Curso sobre las relaciones Filosofía-Religión en al-Andalus que tuvo lugar durante los días 23 y 24 de mayo de 2006.</p>
              <p>Participación en el IV Máster Universitario de Comunicación y Cultura organizado en Málaga por la Universidad de Málaga y “Ámbito Cultural” de El Corte Inglés con un Curso sobre Pensamiento, centrado en los Sofistas, Platón e Ibn Jaldún, durante los días 14, 15 y 16 de noviembre de 2006.</p>
              <p>Participación en el V Máster Universitario de Comunicación y Cultura organizado en Málaga por la Universidad de Málaga y “Ámbito Cultural” de El Corte Inglés con un Curso sobre Pensamiento Contemporáneo (Gramsci, Sartre y Gadamer) celebrado durante los días 2, 4 y 8 de marzo de 2008.</p>
              <p>Impartición del Curso sobre “La Filosofía de al-Andalus” durante los días 28, 29 y 30 de mayo de 2009 en el Núcleo Universitario Rafael Rangel en Trujillo (Venezuela), perteneciente a la Universidad de Los Andes (ULA).</p>
              <p>Dirección del Curso “El legado cultural de al-Andalus: de la vida cotidiana a la ciencia”, celebrado en Palma de Mallorca durante los días 20 y 21 de febrero de 2009 y organizado por el Centro Asociado de la UNED-Les Illes Balears.</p>
              <p>Dirección del Curso “Cuatro sabios sefardíes de la España Medieval: Filosofía, Cábala y Literatura”, celebrado en Palma de Mallorca entre los días 20 de octubre al 10 de noviembre de 2009 y organizado por el Centro Asociado de la UNED-Les Illes Balears.</p>
              <p>Dirección del Curso de Verano de la UNED “Literatura y Filosofía: la originalidad del pensamiento español desde la Celestina a Gracián”, celebrado en Ávila del 5 al 9 de Julio de 2010.</p>
              <p>Dirección del Curso sobre “La mujer y el Islam”, celebrado en el C.A. de la UNED de Les Illes Balears (Palma de Mallorca) durante los días 19 y 20 de Febrero de 2010.</p>
              <p>Dirección del Curso “Del colonialismo a la globalización: luces y sombras del mundo árabe contemporáneo”, celebrado en el C.A. de la UNED en Les Illes Balears los días 4 y 5 de marzo de 2011.</p>
              <p>Dirección del Curso “El renacer del mundo árabe: luchas, esperanzas y decepciones”, celebrado en el C.A. de Les Illes Balears dentro de los Cursos de Verano de la UNED durante los días 28 al 30 de junio de 2012.</p>
              <p>Dirección del Curso “Política y Sociedad en el mundo islámico: realidades, mitos y prejuicios”, celebrado en el C.A. de Les Illes Balears dentro de los Cursos de Verano de la UNED durante los días 4 al 6 de julio de 2013.</p>
              <p>Dirección del Curso “Arte, Literatura y Gastronomía en al-Andalus”, celebrado en el C.A. de la UNED/Les Illes Balears durante los días 3 y 4 de octubre de 2014.</p>
              <p>Seminario impartido en la Facultad de Filosofía de la Universidad Complutense de Madrid sobre el tema "La filosofía de Epicuro como búsqueda de autarquía", durante los meses de abril y mayo de 1987.</p>
              <p>Seminario impartido en la Facultad de Filosofía de la Universidad Complutense de Madrid sobre el tema "El tratado aristotélico Sobre el alma: nuevas perspectivas", durante el mes de febrero de 1988.</p>
              <p>Curso de Doctorado impartido en la Facultad de Filosofía de la UNED durante el curso académico 1987-1988 sobre "La Psicología de Aristóteles".</p>
              <p>Seminario impartido en la Facultad de Filosofía de la Universidad Complutense de Madrid sobre el tema "Algunos problemas de fondo de la Política de Aristóteles", durante los meses de febrero a abril de 1989.</p>
              <p>Curso de Doctorado impartido en la Facultad de Filosofía de la UNED durante el curso académico 1990-1991 con el título "Hacia la autonomía de la filosofía en el pensamiento medieval: la alternativa crítica de Averroes (I). Razón y fe en Fasl al-maqāl".</p>
              <p>Curso impartido en la Facultad de Filosofía y Letras, Sección de Filosofía, de la Universidad Pontificia de Comillas sobre "Filosofía Griega", durante los meses de abril y mayo de 1992.</p>
              <p>Curso de Doctorado impartido en la Facultad de Filosofía de la UNED durante el curso académico 1992-1993 con el título "Hacia la autonomía de la filosofía en el pensamiento medieval: la alternativa crítica de Averroes (II). Crítica a la teología en Tahāfut al-tahāfut ".</p>
              <p>Curso de Doctorado impartido en la Facultad de Filosofía de la UNED durante el curso académico 1994-1995 sobre "Una lectura filosófica del Quijote (I). Parodia, encantamiento y realidad histórica: la estructura del Quijote ".</p>
              <p>Curso de Doctorado impartido en la Facultad de Filosofía de la UNED durante el curso 1995-1996 sobre "Una lectura filosófica del Quijote (II). Humanismo, crítica social y utopía en el Quijote ".</p>
              <p>Coordinador de Doctorado del Departamento de Filosofía de la UNED durante el curso académico 1996-1997.</p>
              <p>Curso de Doctorado impartido en la Facultad de Filosofía de la UNED durante el curso académico 1997-1998 sobre "Filosofía y Mística en Ibn Tufayl".</p>
              <p>Curso de Doctorado impartido en la Facultad de Filosofía de la UNED durante el curso 2004-2005 sobre “La noética de Averroes en el Gran Comentario al libro sobre el alma de Aristóteles”.</p>
              <p>Curso de Doctorado impartido en la Facultad de Filosofía de la UNED durante el curso 2006-2007 sobre “En torno a El collar de la paloma: erotismo, estética e ideología en Ibn Hazm de Córdoba”.</p>
              <p>Curso de Doctorado impartido en la Facultad de Filosofía de la UNED durante el curso 2007-2008 sobre “La recepción medieval de la Ética aristotélica: el Comentario de Averroes a la Ética nicomáquea”.</p>
              <p>Curso de Doctorado impartido en la Facultad de Filosofía de la UNED durante el curso 2008-2009 sobre “El pensamiento crítico de Baltasar Gracián”.</p>
              <p>Curso de Doctorado impartido en la Facultad de Filosofía de la UNED durante el curso 2009-2010 sobre “El pensamiento crítico de Baltasar Gracián”.</p>
              <p>Dirección del Curso “Homenaje a Hegel en el 150 aniversario de su muerte” celebrado del 13 al 27 de noviembre de 1981 y organizado por la Universidad de Málaga, la Diputación Provincial de Málaga y la Caja de Ahorros Provincial de Málaga.</p>
              <p>Dirección del Curso titulado “Al encuentro de Averroes” celebrado en la sede central de la UNED del 22 al 31 de octubre de 1986 y organizado por el Departamento de Historia de la Filosofía.</p>
              <p>Participación en el Seminario “Pasado y presente del mundo judío”, organizado en Tudela por el Centro Asociado de la UNED de Navarra, con una conferencia sobre “El pensamiento judío en la España medieval” celebrada el día 14 de abril de 1989.</p>
              <p>Seminario impartido en el C.A. de la UNED en Las Palmas de Gran Canarias sobre el tema "Un pensamiento en la frontera: génesis y desarrollo de la filosofía en al-Andalus", del 4 al 6 de marzo de 1991.</p>
              <p>Dirección del Curso de Formación del Profesorado "Tres interpretaciones del pensamiento ético-político de Aristóteles", desarrollado en la UNED en los cursos académicos 1992-1993 y 1993-1994.</p>
              <p>Dirección del Curso de Formación del Profesorado "Poder y utopía en el pensamiento español del Siglo de Oro: Cervantes y Quevedo", desarrollado en la UNED durante los cursos académicos 1994-1995 y 1995-1996.</p>
              <p>Participación en el Curso “El mundo clásico en la tradición literaria. I. Edad Media y Renacimiento” organizado por el Departamento de Filología Clásica de la Facultad de Filología de la UNED con una conferencia titulada “La presencia griega en la tradición cultural árabe” pronunciada el día 22 de febrero de 1996.</p>
              <p>Participación en el Curso titulado “Pensamiento Medieval II. Filosofía Judía en España” organizado por la Fundación Fernando Rielo con una conferencia sobre “La filosofía de Maimónides como horizonte de problemas” pronunciada el día 7 de mayo de 1996 en la sede de la Fundación.</p>
              <p>Participación en la “Semana de Homenaje al Maestro D. Emilio García Gómez”, organizada por el Instituto Egipcio de Estudios Islámicos en Madrid con la colaboración de la Real Academia de la Historia y la Real Academia de la Lengua, con una conferencia titulada “La semilla inmortal de Don Emilio García Gómez” pronunciada el día 20 de junio de 1996 en la sede del Instituto Egipcio.</p>
              <p>Dirección del Curso "Razón, naturaleza y sociedad: el pensamiento de Averroes y su influencia", celebrado en Ávila del 20 al 24 de julio de 1998, dentro de los Cursos de Verano de la UNED.</p>
              <p>Participación en el ciclo de conferencias “In memoriam Averroes” organizado por el Ayuntamiento de Córdoba con una conferencia titulada “Averroes y la revolución intelectual del siglo XIII” celebrada el día 10 de diciembre de 1998 en el Alcázar de los Reyes Cristianos de Córdoba.</p>
              <p>Dirección del Curso "Una aproximación al mundo islámico" celebrado en el C.A. de la UNED de Madrid "Gregorio Marañón" del 30 de marzo al 27 de abril del 2000.</p>
              <p>Codirección del Curso Internacional "Egipto y el VIII Milenio" celebrado en el Palacio Ducal de Medina Sidonia (Sanlúcar de Barrameda, Cádiz) del 3 al 7 de julio del 2000, dentro de los Cursos de Verano de la UNED.</p>
              <p>Dirección del Curso de Otoño de la UNED titulado "La cultura en al-Andalus", celebrado en el Palacio Ducal de Medina Sidonia del 7 al 10 de diciembre del 2000.</p>
              <p>Dirección del Curso de Verano de la UNED titulado “La Emigración en España”, celebrado durante los días 1 al 5 de junio de 2002 en el Palacio Ducal de Medina Sidonia (Sanlúcar de Barrameda).</p>
              <p>Dirección del Curso “Las Gnosis del Libro” organizado por el Departamento de Filosofía de la UNED y el Departamento de Filosofía de la Universitat Autònoma de Barcelona y celebrado en la Escuela de Traductores de Toledo, Toledo, del 1 al 3 de mayo de 2003.</p>
              <p>Dirección del Curso de Verano de la UNED titulado “Claves del pensamiento europeo contemporáneo”, celebrado durante los días 7 al 11 de julio de 2003 en el Palacio Ducal de Medina Sidonia (Sanlúcar de Barrameda).</p>
              <p>Participación en el Curso “Tolerancia e Islam” organizado por la Escuela de Traductores, la Facultad de Ciencias Jurídicas y Sociales y la Facultad de Humanidades de la Universidad de Castilla-La Mancha (UCLM) con una conferencia sobre “Tolerancia y convivencia en al-Andalus” pronunciada el día 3 de marzo de 2004 en la Escuela de Traductores de Toledo.</p>
              <p>Director del Curso “El mundo medieval de Maimónides: la aportación a la cultura judía”, organizado en Palma de Mallorca por el Centro Asociado de la UNED en Baleares y el Departamento de Filosofía de la UNED durante los meses de octubre y noviembre de 2004.</p>
              <p>Dirección del Curso de Verano de la Universidad de Málaga titulado “Francisco Giner de los Ríos: su pensamiento e influencia” y celebrado en la ciudad de Ronda del 4 al 8 de julio de 2005.</p>
              <p>Participación en el II Máster Universitario de Comunicación y Cultura, organizado en Málaga por la Universidad de Málaga y “Ámbito Cultural” de El Corte Inglés, con un Curso titulado “Tres perspectivas filosóficas: Aristóteles, Averroes y Russsell”, desarrollado durante los días 18, 21 y 25 de mayo de 2005.</p>
              <p>Participación en el III Máster Universitario de Comunicación y Cultura organizado en Málaga por la Universidad de Málaga y “Ámbito Cultural” de El Corte Inglés con un Curso sobre las relaciones Filosofía-Religión en al-Andalus que tuvo lugar durante los días 23 y 24 de mayo de 2006.</p>
              <p>Participación en el IV Máster Universitario de Comunicación y Cultura organizado en Málaga por la Universidad de Málaga y “Ámbito Cultural” de El Corte Inglés con un Curso sobre Pensamiento, centrado en los Sofistas, Platón e Ibn Jaldún, durante los días 14, 15 y 16 de noviembre de 2006.</p>
              <p>Participación en el V Máster Universitario de Comunicación y Cultura organizado en Málaga por la Universidad de Málaga y “Ámbito Cultural” de El Corte Inglés con un Curso sobre Pensamiento Contemporáneo (Gramsci, Sartre y Gadamer) celebrado durante los días 2, 4 y 8 de marzo de 2008.</p>
              <p>Impartición del Curso sobre “La Filosofía de al-Andalus” durante los días 28, 29 y 30 de mayo de 2009 en el Núcleo Universitario Rafael Rangel en Trujillo (Venezuela), perteneciente a la Universidad de Los Andes (ULA).</p>
              <p>Dirección del Curso “El legado cultural de al-Andalus: de la vida cotidiana a la ciencia”, celebrado en Palma de Mallorca durante los días 20 y 21 de febrero de 2009 y organizado por el Centro Asociado de la UNED-Les Illes Balears.</p>
              <p>Dirección del Curso “Cuatro sabios sefardíes de la España Medieval: Filosofía, Cábala y Literatura”, celebrado en Palma de Mallorca entre los días 20 de octubre al 10 de noviembre de 2009 y organizado por el Centro Asociado de la UNED-Les Illes Balears.</p>
              <p>Dirección del Curso de Verano de la UNED “Literatura y Filosofía: la originalidad del pensamiento español desde la Celestina a Gracián”, celebrado en Ávila del 5 al 9 de Julio de 2010.</p>
              <p>Dirección del Curso sobre “La mujer y el Islam”, celebrado en el C.A. de la UNED de Les Illes Balears (Palma de Mallorca) durante los días 19 y 20 de Febrero de 2010.</p>
              <p>Dirección del Curso “Del colonialismo a la globalización: luces y sombras del mundo árabe contemporáneo”, celebrado en el C.A. de la UNED en Les Illes Balears los días 4 y 5 de marzo de 2011.</p>
              <p>Dirección del Curso “El renacer del mundo árabe: luchas, esperanzas y decepciones”, celebrado en el C.A. de Les Illes Balears dentro de los Cursos de Verano de la UNED durante los días 28 al 30 de junio de 2012.</p>
              <p>Dirección del Curso “Política y Sociedad en el mundo islámico: realidades, mitos y prejuicios”, celebrado en el C.A. de Les Illes Balears dentro de los Cursos de Verano de la UNED durante los días 4 al 6 de julio de 2013.</p>
              <p>Dirección del Curso “Arte, Literatura y Gastronomía en al-Andalus”, celebrado en el C.A. de la UNED/Les Illes Balears durante los días 3 y 4 de octubre de 2014.</p>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle class="color">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>{{'app.crit5' | translate}}</b>
            </mat-panel-title>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </mat-expansion-panel-header>
          <div class="linea">
              <p>Conferencia pronunciada en la Universidad de Granada el día 23 de abril de 1981 sobre “La teoría de la libertad y el concepto de clinamen en Epicuro” dentro del Seminario titulado “Epicuro: análisis crítico y actualidad de su pensamiento”, organizado por los Departamentos de Historia de la Filosofía y de Filología Clásica.</p>
              <p>Conferencia sobre “La concepción de la historia de la filosofía en Hegel” pronunciada en el Palacio de la Diputación Provincial de Málaga el día 19 de noviembre de 1981 en el Curso “Homenaje a Hegel en el 150 aniversario de su muerte”.</p>
              <p>Conferencia sobre “La filosofía en al-Andalus” impartida en el Curso de Verano de la UNED celebrado en Alhucemas (Marruecos) en agosto de 1986.</p>
              <p>Conferencia sobre “Continuidad y ruptura en el aristotelismo de Averroes” celebrada en el salón de actos de la UNED el día 30 de octubre de 1986 dentro del Curso “Al encuentro de Averroes”.</p>
              <p>Conferencia sobre “Pluralismo, tolerancia y mestizaje en al-Andalus. Una reflexión sobre su mundo intelectual” pronunciada el día 30 de enero de 1991 en la Facultad de Filosofía y Letras de Córdoba dentro del Homenaje al Califa ‘Abd al-Rahmān III en el XI Centenario de su nacimiento, organizado por el Ayuntamiento de Córdoba, la Real Academia de Córdoba y la Facultad de Filosofía y Letras.</p>
              <p>Conferencia impartida en el Centro Teológico de Las Palmas el día 5 de marzo de 1991 sobre “Religión y filosofía en la Edad Media: tres perspectivas andalusíes”.</p>
              <p>Conferencia sobre “Avicena y su época” impartida en el Ateneo de Madrid el día 19 de abril de 1991.</p>
              <p>Conferencia con el título “Filosofía y religión en Ibn Hazm, Ibn Tufayl y Averroes” impartida en el Centro Asociado de la UNED de Navarra, Pamplona, 10 de mayo de 1991.</p>
              <p>Conferencia pronunciada el día 22 de noviembre de 1991 en el Centro Asociado de la UNED en la Seo de Urgel con el título “Pensar en tiempo de crisis: una aproximación a la Filosofía Helenística”.</p>
              <p>Conferencia sobre “Las Humanidades en el pensamiento filosófico español” en el Curso de Verano de la UNED titulado “¿Para qué las Humanidades en un mundo tecnológico?” celebrado en Ávila en julio de 1993.</p>
              <p>Profesor Conferenciante invitado por la Universidad de Zimbabwe (Harare, Zimbabwe), en octubre de 1993.</p>
              <p>Conferencia sobre “Propuestas para una regeneración de la vida pública: una reflexión actual desde Gramsci” pronunciada en Córdoba el día 17 de marzo de 1995 dentro del ciclo de conferencias titulado “Filosofía y Política: tres perspectivas” organizado por el Centro Asociado de la UNED de Córdoba.</p>
              <p>Conferencias sobre “Contribución hispanomusulmana a la magia medieval: en torno al Picatrix” y sobre “Magia y astrología en el Islam medieval” en el Curso de Verano de la UNED titulado “Filosofía y ciencias ocultas. Intersecciones entre filosofía y astrología-magia-alquimia” celebrado en Ávila en julio de 1995.</p>
              <p>Conferencia sobre “Ortega y Andalucía” en el Curso de Verano de la UNED titulado “Unamuno y Ortega: la idea de España” celebrado en Ávila del 15 al 19 de julio de 1996.</p>
              <p>Conferencias sobre “La muerte de Sócrates como proceso a la filosofía” y sobre “Durante veinte años debemos impedir a este cerebro que funcione: Antonio Gramsci, víctima del fascismo italiano” en el Curso de Verano de la UNED titulado “Procesos inquisitoriales contra la filosofía y los filósofos” celebrado en La Seu d’Urgell (Lérida) del 22 al 26 de julio de 1996.</p>
              <p>Profesor Conferenciante invitado por la Universidad de Cuenca (Ecuador), en octubre de 1996. Conferencias sobre “Una introducción al pensamiento de Gramsci” y sobre “La contribución de Tomás de Aquino al pensamiento político medieval” en la Facultad de Filosofía, Letras y Ciencias de la Educación los días 10 y 17 de octubre de 1996, respectivamente.</p>
              <p>Profesor Conferenciante invitado por la Universidad de Zimbabwe (Harare, Zimbabwe), en diciembre de 1996. Conferencias sobre “El africanismo español: del colonialismo a la cooperación para el desarrollo” y sobre “Los derechos de los indios en el pensamiento social español del Siglo de Oro” pronunciadas los días 11 y 12 de diciembre de 1996 en el Departamento de Estudios Filosóficos y Religiosos de la Universidad de Zimbabwe. Conferencia sobre “El Quijote como utopía social” pronunciada el día 12 de diciembre de 1996 en el Departamento de Literatura de la misma Universidad africana.</p>
              <p>Conferencia sobre “Objetivos, proyectos y programas de la educación a distancia” pronunciada el día 18 de octubre de 1996 en la Facultad de Ciencias Humanas y Sociales de la Universidad Politécnica Salesiana de Cuenca (Ecuador).</p>
              <p>Conferencia sobre “La Ciudad ideal de al-Farabi: utopía y realismo político” pronunciada en el Instituto Egipcio de Estudios Islámicos de Madrid el día 15 de enero de 1997.</p>
              <p>Conferencia sobre “El marxismo y el problema de la violencia” en el Curso de Verano de la UNED titulado “Filosofía y violencia” celebrado en Ávila en julio de 1997.</p>
              <p>Conferencia sobre “El problema del conocimiento en Averroes” en el Curso de Verano de la UNED titulado “Razón, naturaleza y sociedad: el pensamiento de Averroes y su influencia” celebrado en Ávila en julio de 1998.</p>
              <p>Conferencia sobre “Al-Andalus: el legado cultural de la España islámica” en el Curso titulado “Una aproximación al mundo islámico” celebrado en el Centro de la UNED de Madrid “Gregorio Marañón” durante los meses de marzo y abril de 2000.</p>
              <p>Conferencia sobre “El antiguo Egipto en la cultura griega” en el Curso titulado “Egipto y el VIII Milenio” celebrado en el Palacio Ducal de Medina Sidonia (Sanlúcar de Barrameda, Cádiz) en julio de 2000.</p>
              <p>Conferencia sobre Averroes en el Instituto Cervantes de Casablanca (Marruecos) el día 24 de octubre de 2002, organizada por la Embajada de España en Marruecos y Cooperación Española.</p>
              <p>Conferencia sobre Averroes en el Instituto Cervantes de Tánger (Marruecos) el día 28 de octubre de 2002, organizada por la Embajada de España y Cooperación Española.</p>
              <p>“¿Cómo pensamos? Averroes y el problema del intelecto”, Conferencia pronunciada en la Real Academia de Córdoba el día 26 de marzo de 2003.</p>
              <p>Conferencia sobre “Del naturalismo a la ecología: la contribución de al-Andalus” en el Curso de Verano titulado “Naturaleza, técnica y civilización. Bases para pensar la ecología” celebrado en Ávila del 5 al 9 de julio de 2004.</p>
              <p>“Al-Andalus y los orígenes de la modernidad”, Conferencia pronunciada en el Ateneo de Málaga el día 15 de diciembre de 2004.</p>
              <p>“Religión y filosofía en el Islam medieval: tres perspectivas andalusíes”, Conferencia pronunciada en el Ateneo de Málaga el día 21 de septiembre de 2005 dentro del Proyecto INTERREG III-A, patrocinado por la Unión Europea.</p>
              <p>Conferencia sobre “Al-Andalus: primer Renacimiento en suelo europeo”, impartida en la Real Academia de Córdoba el día 15 de noviembre de 2005 dentro de las III Jornadas de Estudios Andalusíes.</p>
              <p>Conferencia pronunciada el día 11 de mayo de 2006 en el I.E.S. “Juan Ramón Jiménez” de Casablanca (Marruecos) sobre “La nueva concepción de la Historia en Ibn Jaldún”, organizada por la Consejería de Educación de la Embajada de España en Rabat.</p>
              <p>Lección magistral sobre “Al-Andalus, primer Renacimiento europeo”, impartida en el salón de actos de la Casa del Jalifa de Ronda el día 28 de junio de 2006 con motivo de la clausura del curso académico 2005-2006 en el C.A. de la UNED de Málaga.</p>
              <p>“De Avempace a Averroes: el Siglo de Oro de la filosofía andalusí”, Conferencia pronunciada dentro de los Cursos de Verano de la Universidad de Málaga, Vélez Málaga, 24 de julio de 2007.</p>
              <p>“La contribución de al-Andalus a la renovación de la cultura occidental en la Edad Media y el Renacimiento”, Conferencia pronunciada en “Ámbito Cultural” de El Corte Inglés, Málaga, el día 14 de noviembre de 2007.</p>
              <p>“El pensamiento de Averroes y su influencia en Occidente”, Conferencia pronunciada en el Instituto Español de</p>
              <p>Enseñanza Secundaria “Nuestra Señora del Pilar” de Tetuán (Marruecos) el día 21 de abril de 2008.</p>
              <p>“El pensamiento de Averroes y su influencia en Occidente”, Conferencia pronunciada en el Instituto Español de Enseñanza Secundaria “Severo Ochoa” de Tánger (Marruecos) el día 22 de abril de 2008.</p>
              <p>“La filosofía islámica: su papel en la recuperación de la ciencia griega y en el renacer del pensamiento europeo”, Conferencia pronunciada en el Centro de la UNED en las Islas Baleares, Palma de Mallorca, 22 de febrero de 2008.</p>
              <p>“El pensamiento de al-Andalus y su impacto en la renovación de la cultura europea”, Conferencia pronunciada en el Museo del Patrimonio Municipal de Málaga el 6 de agosto de 2008 dentro del Festival de Cultura Andalusí.</p>
              <p>“Los árabes, maestros de Occidente en el mundo medieval”, Conferencia pronunciada en la Institución «Fernando el Católico» de la Diputación de Zaragoza, Zaragoza, 22 de octubre de 2008.</p>
              <p>“Averroes, sabio andalusí del siglo XII”, Conferencia pronunciada en Ávila el 2 de julio de 2008 dentro de Curso de Verano de la UNED titulado “Grandes personajes de la Edad Media”, organizado por el Departamento de Historia Medieval.</p>
              <p>“Grandes textos de pensamiento a la luz de la interculturalidad: Aristóteles y Averroes”, Conferencia pronunciada el 29 de octubre de 2008 en “Ámbito Cultural” de Málaga dentro del II Simposio El Texto Cultural, organizado por la Universidad de Málaga y Ámbito Cultural de El Corte Inglés.</p>
              <p>“Las ciencias en al-Andalus: recepción y recreación del legado griego”, Conferencia pronunciada en Palma de Mallorca el día 20 de febrero de 2009, en el Centro Asociado de la UNED-Les Illes Balears, dentro del Curso “El legado cultural de al-Andalus: de la vida cotidiana a la ciencia”.</p>
              <p>“Al-Andalus y nosotros: entre el imaginario árabe y la reconstrucción de su legado por el medievalismo”, Conferencia pronunciada en Guareña (Badajoz) el día 28 de abril de 2009, dentro del Curso “Aproximación al mundo árabe”, organizado por el Instituto de Enseñanza Secundaria “Eugenio Frutos” .</p>
              <p>“Al-Andalus en el espejo occidental y en el imaginario árabe”, Conferencia pronunciada en el Núcleo Universitario Rafael Rangel de Trujillo (Venezuela) el día 28 de mayo de 2009 en el Congreso Internacional “Presencia y Crítica. Literatura: imaginarios y certezas” organizado por la Universidad de Los Andes, ULA.</p>
              <p>“Ibn Gabirol y el inicio de la filosofía en al-Andalus”, Conferencia pronunciada en Palma de Mallorca el día 20 de octubre de 2009, en el Centro Asociado de la UNED -Les Illes Balears, dentro del Curso “Cuatro sabios sefardíes de la España Medieval: Filosofía, Cábala y Literatura”</p>
              <p>“De la cultura integral a la diversidad cultural: reflexiones actuales a partir de Gramsci”, Comunicación presentada al Congreso Internacional “Gramsci y la sociedad intercultural” el día 3 de diciembre de 2009 en la Universitat Pompeu Fabra de Barcelona</p>
              <p>“Conferencia en el C.A. de la UNED en Les Illes Balears (Palma de Mallorca) el día 20 de febrero de 2010 sobre “Tres filósofos islámicos (Ibn Hazm, Averroes y al-Yabri) reflexionan sobre el papel social de la mujer”.</p>
              <p>Conferencia en el C.A. de la UNED en Huelva el día 23 de marzo de 2010 con el título “La herencia cultural de al-Andalus”</p>
              <p>Conferencia sobre “La huella morisca: del Mancebo de Arévalo a Ricote” en el Curso de Verano de Ávila “Literatura y Filosofía”, pronunciada el día 8 de Julio de 2010.</p>
              <p>Participación en el Homenaje al pensador egipcio Nasr Abu Zayd, celebrado en Casa Árabe de Madrid el 15 de noviembre de 2010, con la ponencia titulada “Hermenéutica y crítica en el pensamiento de Nasr Abu Zayd”.</p>
              <p>Conferencia sobre “Los intelectuales árabes ante los retos de la modernidad”, pronunciada en el C.A. de la UNED en Les Illes Balears (Palma de Mallorca) el día 5 de marzo de 2011.</p>
              <p>Participación como Conferenciante en el Debate El Paradigma de Córdoba en el pasado. Una cultura, tres religiones, celebrado en el salón de actos del Rectorado de la Universidad de Córdoba el día 3 de febrero de 2011 y organizado por Casa Árabe y la UNESCO, con la Ponencia titulada “Córdoba, casa de las ciencias y crisol de culturas”.</p>
              <p>Conferencia en la Fundación Tres Culturas sobre “La influencia de Averroes en la renovación del pensamiento europeo”, pronunciada en Sevilla el día 26 de mayo de 2011.</p>
              <p>Presidente del Tribunal que juzgó la Tesis Doctoral titulada “El Logos del tiempo: Introducción filosófica a la obra de María Zambrano”, realizada por D. José Carlos Rodríguez Álvarez y leída en la Universidad Pontificia de Salamanca el día 25 de noviembre de 2011.</p>
              <p>Conferencia sobre “Algacel, el teólogo ilustrado que criticó la filosofía” el día 1 de diciembre de 2011 en la Escuela Técnica Superior de Arquitectura de la Universidad de Málaga.</p>
              <p>“Averroes’ Contribution to the Renewal of Medieval Philosophy”, Conferencia pronunciada como profesor invitado en la Universidad de Gante (Bélgica) el día 27 de abril de 2012.</p>
              <p>Conferencias pronunciadas en el Curso de Verano “El renacer del mundo árabe: luchas, esperanzas y decepciones”, celebrado en el C.A. de la UNED en Les Illes Balears del 28 al 30 de junio de 2012: “Edward Said y la cuestión palestina” (28 de junio); “Libia, las mentiras de una guerra” (29 de junio); y “Los intelectuales árabes y su apuesta por la democracia” (30 de junio).</p>
              <p>“Al-Andalus y el giro renovador de la Filosofía Medieval”, conferencia en la Facultad de Filosofía de la Universidad de Barcelona el día 28 de nNoviembre de 2012.</p>
              <p>Presentación del libro Introducción a la filosofía medieval en la Reial Acadèmia de Bones Lletres de Barcelona el día 29 de noviembre de 2012 en acto presidido por el Dr. Pere Molas Ribalta, presidente de la Acadèmia, y en el que intervinieron D. José Martínez Gázquez, Catedrático de la Universitat Autònoma de Barcelona (UAB) y Académico, y D. Miguel Candel, Profesor Titular de la Universitat de Barcelona (UB).</p>
              <p>Conferencias pronunciadas en el Curso de Verano titulado “Política y Sociedad en el mundo islámico: realidades, mitos y prejuicios”, celebrado en el C.A. de la UNED en Les Illes Balears del 4 al 6 de julio de 2013:</p>
              <p>“Política y Religión en el Islam clásico” (4 de julio); y “Los nuevos intelectuales musulmanes” (6 de julio).</p>
              <p>Conferencias pronunciadas en el Curso “Arte, Literatura y Gastronomía en al-Andalus”, celebrado en el C.A. de la UNED/Illes Balears del 3 al 4 de octubre de 2014: “La literatura de al-Andalus y su influencia en la lengua española” (4 de octubre); y “Una revolución silenciosa y profunda: la nueva gastronomía andalusí”</p>
              <p>Lección inaugural del curso académico 2014-2015 de la UNED/Les Illes Balears el día 3 de octubre de 2014 en el salón de plenos del Consell de Mallorca con el título “Los intelectuales árabes ante los retos de nuestro tiempo”.</p>
              <p>Conferencia en la Fundación Juan March con el título “Averroes, el filósofo andalusí que abrió nuevos caminos en el pensamiento medieval”, Madrid, 13 de febrero de 2020.</p>
              <p>Conferencia en el I.E.S. "Averroes" de Córdoba el día 25 de marzo de 2022 con el título "Averroes, vida y obra" con motivo del 50 aniversario de la creación del Centro.</p>
              <p>Conferencia en la "Escuela de Filosofía" (Madrid) sobre "Maimónides, el sabio andalusí que unió filosofía y judaísmo" el día 25 de octubre de 2022.</p>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle class="color">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>{{'app.crit6' | translate}}</b>
            </mat-panel-title>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </mat-expansion-panel-header>
          <div class="linea">
              <p>1. Tesis Doctoral leída en la Facultad de Filosofía de la UNED en marzo de 1995 por D. José Manuel San Baldomero Ucar con el título " Versus Grecia. La filosofía radical de Xavier Zubiri". Recibió el Premio Extraordinario de Doctorado de la Facultad para el curso académico 1994-1995. Editada como libro: La filosofía de Xavier Zubiri versus la filosofía griega, Madrid, Fundación Universitaria Española, 1998.</p>
              <p>2. Tesis Doctoral sobre "La concepción de la filosofía en el Tahafut al-tahafut de Averroes" leída por Dª Idoia Maiza Ozcoidi en julio de 1997 en la Facultad de Filosofía de la UNED, calificada con Apto "cum laude" por unanimidad. Ha sido también editada como libro: La concepción de la filosofía en Averroes. Análisis crítico del Tahāfut al-tahāfut, Madrid Trotta/UNED, 2001.</p>
              <p>3. Tesis Doctoral sobre "Filosofía e Ilustración en Jovellanos" leída por D. José Manuel Souto Rodriguez el día 25 de marzo de 2002 en la Facultad de Filosofía de la UNED, siendo calificada con Sobresaliente por unanimidad. Ha sido publicada como libro: Filosofía e Ilustración en Jovellanos, Oviedo, Laria, 2011.</p>
              <p>4. Tesis Doctoral sobre "Formas marginales de la racionalidad en las ciencias ocultas" leída por Dª Esther C. García Tejedor el día 9 de mayo de 2002 en la Facultad de Filosofía de la UNED, calificada con Sobresaliente.</p>
              <p>5. Tesis Doctoral de D. Miguel Jordá Tomás sobre “Rebeldía, amistad y erotismo en Baltasar Gracián. Aproximación a un pensamiento y un mundo cifrados” leída en la Facultad de Filosofía de la UNED el 16 de abril de 2004, calificada con Sobresaliente por unanimidad. Ha sido editada con el título De la rebeldía al erotismo. Introducción a Baltasar Gracían, Zaragoza, Mira Editores, 2007.</p>
              <p>6. Tesis Doctoral sobre “El Corpus Dionysiacum y su influencia en la obra de Juan Escoto Eriúgena” leída por D. Ángel Vicente Valiente Sánchez-Valdepeñas en la Facultad de Filosofía de la UNED el día 16 de marzo de 2005, calificada con Sobresaliente por unanimidad.</p>
              <p>7. Tesis Doctoral sobre “La Nouvelle Droite, un gramscismo de derechas” leída por D. Diego Luis Sanromán Peña el día 21 de junio de 2006 en la Facultad de Filosofía de la UNED, calificada con Sobresaliente por unanimidad. Ha sido publicada como libro: La Nueva derecha. Cuarenta años de agitación metapolítica, Madrid, Centro de Investigaciones Sociológicas, 2008.</p>
              <p>8. Tesis Doctoral sobre “Los fundamentos del derecho islámico en Ibn Rušd. Análisis crítico de la Bidāya” leída por Mohamed Bilal Achmal el día 10 de diciembre de 2015, calificada con Sobresaliente por unanimidad.</p>
              <p>9. Tesis Doctoral sobre “El problema del sujeto en ‘El Quijote’ de Miguel de Cervantes”, leída por Fidel Magán Fabra el día 13 de enero de 2016 en la Facultad de Filosofía de la UNED, calificada con Sobresaliente por unanimidad.</p>
          </div>    
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle class="color">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>{{'app.crit7' | translate}}</b>
            </mat-panel-title>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </mat-expansion-panel-header>
          <div class="linea">
           <p>1. Miguel A. Lizano Ordovás, El alma y los nombres. Una lectura del Crátilo. Facultad de Filosofía, Universidad de Barcelona, 1982.</p>
           <p>2. Pedro Molina García, Génesis y estructuración de la visión marxista-trágica del mundo en la obra de Lucien Goldmann. Facultad de Filosofía y Letras, Universidad de Valencia, enero de 1986.</p>
           <p>3. Jesús Osés Gorráiz, La sociología de Ortega y Gasset. Centro Asociado de la UNED en Navarra, abril de 1987.</p>
           <p>4. Miguel Manzanera Salavert, Teoría y Práctica. La trayectoria intelectual de Manuel Sacristán. Facultad de Filosofía de la UNED, diciembre de 1993.</p>
           <p>5. Fernando Bermejo Rubio, La escisión imposible. (Lectura crítico-sistemática del gonosticismo valentiniano como pensamiento exculpatorio). Madrid, Facultad de Filosofía de la UNED, 1997.</p>
           <p>6. Fermín Lázaro Velasco, La ética social de Antonio Machado: un intento por articular razón y sentimiento en el seno de una creencia objetivista. Facultad de Filosofía de la UNED, 2001.</p>
           <p>7. Antonio Ríos Rojas, Aproximación a una teoría del conocimiento en la Guía de Perplejos de Maimónides a partir de los conceptos de «profecía» y «providencia». Facultad de Filosofía y Letras, Universidad de Salamanca, diciembre de 2002.</p>
           <p>8. Pedro Sánchez Limiñana, Interpretaciones y perspectivas de la modernidad: el sujeto en el pensamiento de Ernst Tugendhat. Facultad de Filosofía de la UNED, diciembre de 2006.</p>
           <p>9. José Carlos Rodríguez Álvarez, El Logos del tiempo: introducción filosófica a la obra de María Zambrano. Facultad de Filosofía, Universidad Pontificia de Salamanca, noviembre de 2011.</p>
           <p>10. Antonio Sparano, Dal summum bonum alla vita sufficiens (la riscoperta del dialogo nell’opera di Marsilio da Padova). Facultad de Filosofía, Universidad de Barcelona, julio de 2016.</p>

           <!--    <p>Participación en la I Semana Andaluza de Filosofía, organizada por la Universidad de Málaga y celebrada en Málaga durante el mes de julio de 1979 con una Comunicación titulada "El dilema libertad-necesidad en Demócrito y Epicuro".</p>
              <p>Participación en el I Congreso Internacional sobre la vida y obra de Maimónides, organizado por el Ministerio de Cultura y la Universidad de Córdoba y celebrado en Córdoba del 8 al 11 de septiembre de 1985, con una Ponencia titulada "Del dios aristotélico al dios judío. Reflexiones sobre los límites del aristotelismo en Maimónides".</p>
              <p>Participación en el Congreso Internacional "Al-Andalus: tradición, creatividad y convivencia", organizado por el Ministerio de Cultura y la Universidad de Córdoba y celebrado en Córdoba del 18 al 24 de enero de 1987, con una Comunicación sobre el tema "Ibn Rusd, renovador de la filosofía en el mundo medieval".</p>
              <p>Participación en el II Encuentro Hispanomexicano de Filosofía Moral y Política, organizado por la Universidad Internacional Menéndez Pelayo y el CSIC y celebrado en Santander y Madrid durante los días 8 al 19 de septiembre de 1986, con una Comunicación titulada "Hacia una filosofía hispánica".</p>
              <p>Participación en el I Congreso Nacional de Filosofía Medieval, organizado por la Sociedad de Filosofía Medieval y celebrado en Zaragoza en diciembre de 1990, con una Comunicación titulada "La concepción de la política en Tomás de Aquino".</p>
              <p>Participación en el Coloquio Internacional "Diálogo filosófico-religioso entre cristianismo, judaísmo e islamismo durante la Edad Media en la Península Ibérica", organizado por la Société Internationale pour l'Étude de la Philosophie Médiévale (SIEPM) y celebrado en San Lorenzo de El Escorial del 23 al 26 de junio de 1991, con una Comunicación sobre el tema "¿Cómo fue posible la germinación de la filosofía en al-Andalus?"</p>
              <p>Participación en el III Congresso Internazionale della S.I.T.A., organizado por la Sociedad Internacional Tomás de Aquino (SITA) y celebrado en Roma en septiembre de 1991, con una Comunicación titulada "Communitas liberorum. En torno a Sententia libri Politicorum de Tomás de Aquino".</p>
              <p>Participación en el III Congreso Internacional Andalusí, organizado por la Universidad de El Cairo y celebrado en El Cairo del 11 al 14 de enero de 1992, con una Comunicación titulada "Ibn Rusd como historiador de la filosofía".</p>
              <p>Participación en el IX Congreso Internacional de Filosofía Medieval, organizado por la Société Internationale pour l'Étude de la Philosophie Médiévale y celebrado en la Universidad de Ottawa (Canadá) en agosto de 1992, con una Comunicación titulada "El concepto de civitas en la teoría política de Tomás de Aquino".</p>
              <p>Participación en las IV Jornadas Internacionales Medievales, organizadas por la Universidad Nacional Autónoma de México (UNAM) y celebradas en México D.F. en enero de 1993, con la ponencia titulada "Del espíritu de la letra a la crítica política en el filósofo hispanomusulmán Ibn Hazm de Córdoba".</p>
              <p>Participación en el Congreso Internacional “L'Epicureismo Greco e Romano”, organizado por el Centro Internazionale per lo Studio dei Papiri Ercolanesi y la Universidad de Nápoles Federico II y celebrado en Nápoles del 19 al 26 de mayo de 1993, con una Comunicación sobre el tema "Lucrecio: una crítica ilustrada a la religión popular".</p>
              <p>Participación en el II Encuentro Interreligioso de Alcalá, de carácter internacional, organizado por la Universidad de Alcalá y celebrado en Alcalá de Henares del 24 al 26 de noviembre de 1997, con una Ponencia titulada "Paz, cultura y religiosidad".</p>
              <p>Participación en el X Congreso Internacional de Filosofía Medieval, organizado por la Société Internationale pour l'Étude de la Philosophie Médiévale y celebrado en Erfurt (Alemania) del 25 al 30 de agosto de 1997, con una Comunicación titulada "Ibn Tufayl y su concepto de filosofía".</p>
              <p>Participación en el “Convegno Internazionale sul tema Averroè, Commentatore di Aristotele”, organizado por el Istituto Universitario Orientale de Nápoles y celebrado en esta ciudad italiana del 14 al 16 de enero de 1999, con una Ponencia titulada "Averroes as a reader of Aristotle's Peri Psykhés ".</p>
              <p>Participación en el IX Congreso Latinoamericano de Filosofía Medieval celebrado en Porto Alegre (Brasil) del 22 al 26 de septiembre de 2003 con una Ponencia titulada “El lenguaje filosófico de Aristóteles en las versiones grecolatina de Moerbeke y árabo-latina de Escoto”.</p>
              <p>Participación en el Congreso Internacional “Maimonide e il suo tempo” celebrado en la Universidad de Milán (Italia) durante los días 13 y 14 de enero de 2005 con una Ponencia titulada “Maimónides y el pensamiento judío de al-Andalus”.</p>
              <p>Participación en la Conferencia Internacional “Diálogo entre las tres Culturas y nuestra herencia europea” organizada por la Academia Europaea en Toledo del 2 al 5 de septiembre de 2007 con una Comunicación titulada “Said al-Andalusí: una evaluación del papel de la investigación y la ciencia en Al-Andalus y en el mundo”.</p>
              <p>Participación en el XII Congreso Internacional de Filosofía Medieval celebrado en Palermo (Italia) del 16 al 22 de septiembre de 2007 con una Comunicación titulada “Razón práctica en Averroes: acerca de la amistad y la felicidad”.</p>
              <p>Participación en el III Simposio Internacional “La huellas del Islam” organizado por las Universidades de Sevilla y Huelva en Almonaster la Real (Huelva) durante los días 12 y 13 de octubre de 2007 con una Ponencia titulada “La recepción del legado filosófico árabe en la Escolástica y el Renacimiento”.</p>
              <p>Participación en el Simposio Internacional «The Life and Work of Ibn Hazm of Cordoba» celebrado en Estambul (Turquía) del 26 al 28 de agosto de 2008, organizado por el German Orient-Institut in Istanbul, con una Ponencia titulada “Ibn Hazm as a philosopher: the central subjects of his thought”.</p>
              <p>Comunicación presentada con el título “Ibn Rushd’s influence on Scholastic and Renaissance Philosophy” en el «International Ibn Rushd Symposium» celebrado en Sivas (Turquía) del 9 al 11 de octubre de 2008 y organizado por la Cumhuriyet Üniversitesi (Universidad Republicana de Sivas).</p>
              <p>Participación en el Simposio Internacional dedicado a al-Ghazali, organizado por la Academia Ambrosiana de Milán (Italia) el día 7 de noviembre de 2011, con una conferencia titulada “La crítica de al-Ghazali al racionalismo filosófico islámico: revisión de una polémica histórica”.</p>
              <p>Participación en el VII Dies Academicus, Classe di Studi sul Vicino Oriente, organizado por la Academia Ambrosiana de Milán el día 8 de noviembre de 2016 con una conferencia titulada “Al-Andalus, encuentro de culturas y foco de nueva luz en Europa”.</p>
              <p>Participación en el Simposio Internacional “Ethics and Politics in the Arab- Islamic Heritage. Ibn Rushd’s influence in his time and in later times” celebrado en Marrakech (Marruecos) y organizado por la Universidad Cadi Ayyad de Marrakech y el Rawafed Center for Studies and Researches in Maghreb Civilization and Mediterranean Heritage, con una ponencia titulada “La recepción de la Ética aristotélica en Averroes y su impacto en el mundo latino medieval”, pronunciada el día 27 de abril de 2020.</p>
              <p>Participación el día 22 de abril de 2021 en las "Jornadas Ibn Gabirol" celebradas en el Museo Picasso de Málaga con una ponencia sobre el pensamiento filosófico del filósofo sefardí.</p> -->
          </div>
        </mat-expansion-panel>
            
      </mat-accordion>  
      <br>
  
</body>
 