import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';




@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {
  

  idioma:string='es';

  constructor(private traductor:TranslateService){
    this.traductor.use(this.idioma)
  
  }
  
  cambiarIdioma(nuevoIdioma:string){
    this.idioma = nuevoIdioma;
    this.traductor.use(this.idioma);
  }

  ngOnInit(): void {
  }

}






