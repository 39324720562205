
<p>Si usted dispone de gestor de correo puede enviar el mensaje directamente pulsando el icono de correo
<a mat-button href="mailto:andresmalmendro@gmail.com/" target="_blank">
    <mat-icon>contact_mail</mat-icon>
  </a>
  En caso contrario, rellene el formulario y envielo.
</p> 

<form class="was-validated">
    <div class="mb-3">
        <label for="validationTextarea" class="form-label">Nombre</label>
        <input class="form-control" id="validationTextarea" placeholder="Campo Obligatorio" required>
        <div class="invalid-feedback">
          Introduzca su nombre.
        </div>
    </div>

    <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">Correo</label>
        <input type="email" class="form-control form-control-lg" id="validationTextarea" placeholder="Campo Obligatorio">
     </div>

     <div class="mb-3">
        <label for="validationTextarea" class="form-label">Asunto</label>
        <input type="text" class="form-control" id="validationTextarea" placeholder="Required example textarea" required>
        <div class="invalid-feedback">
            Introduzca el asunto del correo.
        </div>
    </div>

    <div class="mb-3">
      <label for="validationTextarea" class="form-label">Contenido del correo</label>
      <textarea class="form-control" id="validationTextarea" placeholder="Required example textarea" required></textarea>
      <div class="invalid-feedback">
        Introduzca el contenido del correo.
      </div>
    </div>

    <div class="mb-3">
        <label for="formFile" class="form-label">Adjuntar archivo</label>
        <input class="form-control" type="file" id="formFile">
    </div>
  
    
  
    <div class="mb-3">
      <button class="btn btn-primary" type="submit" disabled>Enviar</button>
    </div>
  </form>

  <form class="row g-3 needs-validation" novalidate>
    <div class="col-md-4 position-relative">
      <label for="validationTooltip01" class="form-label">First name</label>
      <input type="text" class="form-control" id="validationTooltip01" value="Mark" required>
      <div class="valid-tooltip">
        Looks good!
      </div>
    </div>
    <div class="col-md-4 position-relative">
      <label for="validationTooltip02" class="form-label">Last name</label>
      <input type="text" class="form-control" id="validationTooltip02" value="Otto" required>
      <div class="valid-tooltip">
        Looks good!
      </div>
    </div>
    <div class="col-md-4 position-relative">
      <label for="validationTooltipUsername" class="form-label">Username</label>
      <div class="input-group has-validation">
        <span class="input-group-text" id="validationTooltipUsernamePrepend">@</span>
        <input type="text" class="form-control" id="validationTooltipUsername" aria-describedby="validationTooltipUsernamePrepend" required>
        <div class="invalid-tooltip">
          Please choose a unique and valid username.
        </div>
      </div>
    </div>
    <div class="col-md-6 position-relative">
      <label for="validationTooltip03" class="form-label">City</label>
      <input type="text" class="form-control" id="validationTooltip03" required>
      <div class="invalid-tooltip">
        Please provide a valid city.
      </div>
    </div>
    <div class="col-md-3 position-relative">
      <label for="validationTooltip04" class="form-label">State</label>
      <select class="form-select" id="validationTooltip04" required>
        <option selected disabled value="">Choose...</option>
        <option>...</option>
      </select>
      <div class="invalid-tooltip">
        Please select a valid state.
      </div>
    </div>
    <div class="col-md-3 position-relative">
      <label for="validationTooltip05" class="form-label">Zip</label>
      <input type="text" class="form-control" id="validationTooltip05" required>
      <div class="invalid-tooltip">
        Please provide a valid zip.
      </div>
    </div>
    <a href=”mailto:andresmalmendro@gmail.com?
    Subject=Asunto%20del%20mail”>Aquíeltexrewewtoquequieras</a>
    
    <div class="col-12">

    <a href="mailto:andresmalmendro@gmail.com?Subject=Aquí%20el%20asunto%20del%20mail">
      <button class="btn btn-primary" type="submit">Submit form</button>
    </a>  
    </div>
  </form>

  <a href="mailto:elcorreoquequieres@correo.com?Subject=Aquí%20el%20asunto%20del%20mail">Aquí el texto que quieras</a>
 
