<hr style =" height: 5px; background-color: rgb(10, 60, 221); width: 75%;">
<h1>{{'app.info3' | translate}}</h1>

<div class="alert alert-secondary" role="alert">
    <p><a href="https://dialnet.unirioja.es/servlet/autor?codigo=173000" target="_blank" class="alert-link" >Dialnet, Universidad de La Rioja</a></p>
    <hr>
    <p><a href="https://ucm.on.worldcat.org/search?queryString=Andr%C3%A9s%20Mart%C3%ADnez%20Lorca&databaseList=1953,1941,2259,2237,2269,3860,1672,3036,638,3954,3867&sortKey=LIBRARY&clusterResults=false&scope=sz:37703&scope=sz:37628&page=2" target="_blank" class="alert-link">Biblioteca Islámica, Madrid</a></p>
    <hr>
    <p><a href="https://buscador.biblioteca.uned.es/primo-explore/search?query=any,contains,Andr%C3%A9s%20Mart%C3%ADnez%20Lorca&tab=tab1&search_scope=TAB1_SCOPE1&vid=34UNED_VU1&lang=es_ES&offset=0" target="_blank" class="alert-link">Biblioteca Central, UNED</a></p>
    <hr>
    <p><a href="https://www.trotta.es/colecciones/al-andalus-textos-y-estudios/1/?page=1&" target="_blank" class="alert-link">Colección "Al-Andalus", Editorial Trotta, Madrid</a></p>
    <hr>
    <p><a href="https://hiw.kuleuven.be./siepm" target="_blank" class="alert-link" >Société Internationale pour l’Étude de la Philosophie Médiévale (SIEPM)</a></p>
    <hr>
    <p><a href="https://www.ambrosiana.it/en/" target="_blank" class="alert-link">Academia Ambrosiana de Milán</a></p>
    <hr>
    <p><a href="http://opac.regesta-imperii.de/lang_en/autoren.php?name=Druart%2C+Th%C3%A9r%C3%A8se-Anne" target="_blank" class="alert-link">Druart, Thérèse-Anne-RI-Opac-Regesta Imperii</a></p>
    <hr>
    <p><a href="https://www.imarabe.org/fr" target="_blank" class="alert-link">Institut du Monde Arabe, Paris</a></p>
    <hr>
    <p><a href="https://icarabe.org/" target="_blank" class="alert-link" >Instituto da Cultura Árabe, São Paulo</a></p>
    <hr>
    <p><a href="https://www.casadellibro.com/libros" target="_blank" class="alert-link">Casa del Libro, Madrid</a></p>
    <hr>
    <p><a href="https://www.libreriaproteo.com/buscador/simple/busqueda/Andr%C3%A9s+Mart%C3%ADnez+Lorca/pagina/1" target="_blank" class="alert-link">Librería Proteo, Málaga</a></p>
    <hr>
    <p><a href="http://www.internationalgramscisociety.org/" target="_blank" class="alert-link">International Gramsci Society (IGS)</a></p>
    <hr>
    <p><a href="https://elpais.com/autor/edward-said/" target="_blank" class="alert-link">Edward Said</a></p>
    <hr>
    <p><a href="http://www.residencia.csic.es/" target="_blank" class="alert-link">Residencia de Estudiantes</a></p>
    <hr>
    <p><a href="https://www.estampapopular.com/" target="_blank" class="alert-link">Cristóbal Aguilar Barea-Estampa Popular</a></p>
  </div>

