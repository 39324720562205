<hr>
<h1>{{'app.info2' | translate}}</h1>

<div class="container">
    <mat-tab-group mat-stretch-tabs backgroundColor="primary">
        <mat-tab label="{{'app.info5' | translate}}" aria-label="primary">
            <app-videos></app-videos>
        </mat-tab>
        <!--  <mat-tab label="Audios" aria-label="primary">
            <app-audios></app-audios>
        </mat-tab> -->
        <mat-tab label="{{'app.info6' | translate}}" aria-label="primary">
            <app-fotos></app-fotos>
        </mat-tab>
       
    </mat-tab-group>
</div>

