<body>
   
  <div class="alert alert-primary" role="alert">
    <h1>{{'app.nota1' | translate}}</h1>
    <hr>
   <div><img class="border border-primary-subtle rounded" style="float: right;" title="Andrés Martínez Lorca" src="./../../../assets/img/andres_despacho1_recortada.webp" loading="lazy" alt="Andrés Martínez Lorca" width="200" height="200">
    {{'app.nota2' | translate}}</div><br>
   <p>{{'app.nota3' | translate}}</p>
   <p>{{'app.nota4' | translate}}</p>
   <p>{{'app.nota5' | translate}}</p>
   <p>{{'app.nota6' | translate}}</p>
   <p>{{'app.nota7' | translate}}</p>
   <p>{{'app.nota8' | translate}}</p>
  </div>
  
</body>  

