<hr>
<h1 style="text-align: center;">{{'app.info6' | translate}}</h1>
<hr>

<div class="abs-center">
    <div class="row row-cols-1 row-cols-md-1 g-4" style="max-width: 540px; ">
        <div class="col">
            <div class="card text-bg-dark">
                <img src="./../../../../assets/img/../img/personales/04.webp"  class="card-img-top" alt="...">
                <div class="card-body">
                    <p class="card-text">En la escuela de Dalías (Almería) con su padre, maestro, curso 1947-48.</p>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card text-bg-dark">
                <img src="./../../../../assets/img/../img/personales/08.webp"  class="card-img-top" alt="...">
                <div class="card-body">
                    <p class="card-text">Con el escritor inglés Gerald Brenan en su casa de Alhaurín el Grande (Málaga).</p>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card text-bg-dark">
                <img src="./../../../../assets/img/../img/personales/01.webp"  class="card-img-top" alt="...">
                <div class="card-body">
                    <p class="card-text">Con el profesor Aranguren en la Peña Flamenca “Juan Breva” de Málaga.</p>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card text-bg-dark">
                <img src="./../../../../assets/img/../img/personales/02.webp" loading="lazy" class="card-img-top" alt="...">
                <div class="card-body">
                    <p class="card-text">Con el profesor egipcio Mahmud Ali Makki en la cumbre de Sierra de los Filabres, Tetica de Bacares (Almería).</p>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card text-bg-dark">
                <img src="./../../../../assets/img/../img/personales/03.webp" loading="lazy" class="card-img-top" alt="...">
                <div class="card-body">
                    <p class="card-text">Con su hijo Ulises ante el monumento a Quevedo en Torre de Juan Abad (Ciudad Real).</p>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card text-bg-dark">
                <img src="./../../../../assets/img/../img/personales/Estambul, congreso Ibn Hazm III.webp" loading="lazy" class="card-img-top" alt="...">
                <div class="card-body">
                    <p class="card-text">En el “German Orient-Institut” de Estambul (Turquía) presentando una ponencia sobre Ibn Hazm de Córdoba, agosto de 2008.</p>
                </div>
            </div>
        </div>

        <div class="col">
            <div class="card text-bg-dark">
                <img src="./../../../../assets/img/../img/personales/Estambul.webp" loading="lazy" class="card-img-top" alt="...">
                <div class="card-body">
                    <p class="card-text">Frente a la Mezquita Azul en Estambul (Turquía).</p>
                </div>
            </div>
        </div>

        

        <div class="col">
            <div class="card text-bg-dark">
                <img src="./../../../../assets/img/../img/personales/07.webp" loading="lazy" class="card-img-top" alt="...">
                <div class="card-body">
                    <p class="card-text">En la toma de posesión como Académico Correspondiente de la Real Academia de Bellas Artes y Ciencias Históricas de Toledo, octubre 2010.</p>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card text-bg-dark">
                <img src="./../../../../assets/img/../img/personales/Presentación del libro sobre Averroes en Casa Árabe.webp" loading="lazy" class="card-img-top" alt="...">
                <div class="card-body">
                    <p class="card-text">Presentación del libro sobre Averroes en Casa Árabe de Madrid junto al profesor egipcio Mahmud Ali Makki y la directora de la institutición, profesora Gema Martín Muñoz.</p>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card text-bg-dark">
                <img src="./../../../../assets/img/../img/personales/09.webp" loading="lazy" class="card-img-top" alt="...">
                <div class="card-body">
                    <p class="card-text">Ante el Castillo de Coca (Segovia) con su esposa Rosalía y su hijo Ulises.</p>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card text-bg-dark">
                <img src="./../../../../assets/img/../img/personales/Paradigma Corb.webp" loading="lazy" class="card-img-top" alt="...">
                <div class="card-body">
                    <p class="card-text">Con un grupo de arabistas en “El Paradigma de Córdoba en el pasado”, debate organizado por la UNESCO y Casa Árabe en Córdoba, febrero de 2011.</p>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card text-bg-dark">
                <img src="./../../../../assets/img/../img/personales/Rosalia.webp" loading="lazy" class="card-img-top" alt="...">
                <div class="card-body">
                    <p class="card-text">Con su esposa, Rosalía Córdova, en el Teatro Romano de Málaga con la Alcazaba árabe al fondo.</p>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card text-bg-dark">
                <img src="./../../../../assets/img/../img/personales/Presentación libro en Fundación Antonio Gala.webp" loading="lazy" class="card-img-top" alt="...">
                <div class="card-body">
                    <p class="card-text">En la presentación en la Fundación Antonio Gala de Córdoba del libro “Averroes, el sabio cordobés que iluminó Europa”, año 2010.</p>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card text-bg-dark">
                <img src="./../../../../assets/img/../img/personales/Rocio.webp" loading="lazy" class="card-img-top" alt="...">
                <div class="card-body">
                    <p class="card-text">Con su hija Rocío en Málaga</p>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card text-bg-dark">
                <img src="./../../../../assets/img/../img/personales/06.webp" loading="lazy" class="card-img-top" alt="...">
                <div class="card-body">
                    <p class="card-text">En los Jardines de Aranjuez.</p>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card text-bg-dark">
                <img src="./../../../../assets/img/../img/personales/Pablo2.webp" loading="lazy" class="card-img-top" alt="...">
                <div class="card-body">
                    <p class="card-text">Con sus hijos Andrés y Pablo en Cabo de Gata (Almería)</p>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card text-bg-dark">
                <img src="./../../../../assets/img/../img/personales/Instituto Cordb.webp" loading="lazy" class="card-img-top" alt="...">
                <div class="card-body">
                    <p class="card-text">En el Instituto de Enseñanza Media “Averroes” de Córdoba dando una conferencia sobre el filósofo cordobés, marzo de 2022.</p>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card text-bg-dark">
                <img src="./../../../../assets/img/../img/personales/Uli.webp" loading="lazy" class="card-img-top" alt="...">
                <div class="card-body">
                    <p class="card-text">Con su hijo Ulises en La Iglesuela del Tiétar (Toledo) con la sierra de Gredos al fondo.</p>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card text-bg-dark">
                <img src="./../../../../assets/img/../img/personales/05.webp" loading="lazy" class="card-img-top" alt="...">
                <div class="card-body">
                    <p class="card-text">Ante el monumento a Averroes en Córdoba.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<br>



