<body>
  


  <table mat-table
        [dataSource]="dataSource" multiTemplateDataRows
        class="mat-elevation-z8">
    <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
      <th mat-header-cell *matHeaderCellDef><h1>{{'app.pub2' | translate}}</h1>  </th>
      <td class="bg-success p-2 text-dark bg-opacity-10" mat-cell *matCellDef="let element"> {{element[column]}} </td>
    </ng-container>
    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
      <td class="bg-success p-2 text-dark bg-opacity-10" mat-cell *matCellDef="let element">
        <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
          <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
        <div class="example-element-detail"
            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" >

          <div class="example-element-diagram bg-success p-2 text-white bg-opacity-50">
            
              <div class="example-element-description">
                <div class="card text-dark mb-3 border-light text-center card-color card-1" >
                  <div class="row g-0">
                  
                    <div class="col-md-2 card-img-overlay-padding">
                      <br>
                      <img src={{element.src}} class="img-fluid rounded-start" alt="..." >
                    </div>
                    <div class="col-md-8">
                      <div class="card-body">
                    
                        <h4 class="card-title" style="text-align: left;" >{{element.description}}</h4>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
</body>